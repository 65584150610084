import React, { Component } from "react";
import {
  Row,
  FormGroup,
  FormFeedback,
  Modal,
  Button,
  Col,
  Input,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import axios from "axios";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons";
class YieldForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenUser: {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      },
      harvest_date: props.selectedLog ? props.selectedLog.date : null,
      performanceValue: props.selectedLog ? props.selectedLog.value : null,
      selectedLog: props.selectedLog ? props.selectedLog : null,
      performanceInvalid: false,
      loading: false,
      crop: null,
      variety: null,
      observation: props.selectedLog ? props.selectedLog.observation : null,
      //actua para chequear que todo este bien con el sowing
      loadingLogs: null,
      //actua para ver si existe registros de sowing
      validatePerformance: false,
      //si selecciona mas poligonos y alguno no tiene sowing, que no deje avanzar
      validateHaveSowing: false,
      //valida si en los poligonos seleccionados tienen la misma variedad
      checkVarietiesStatus: null,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };
  }

  componentDidMount() {
    this.prevLogs();
    if (this.props.selectedLog?.id) {
      this.checklogs();
    }
  }
  checklogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.harvest_date,
      tokenUser: this.state.tokenUser,
      type: this.props.selectedLog?.type,
    };
    let result = await requestResult(info);
    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };
  prevLogs = async () => {
    let polygonsSelect = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    axios
      .get(
        `https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Sowing`,
        this.state.tokenUser
      )
      .then((logSowing) => {
        if (logSowing.data.length > 0) {
          if (polygonsSelect.length === 1) {
            this.onePolygonSelect(logSowing, polygonsSelect);
          }
          if (polygonsSelect.length > 1) {
            this.multiPolygonsSelect(polygonsSelect, logSowing);
          }
        } else {
          this.setState({
            validatePerformance: true,
          });
        }
      });
  };

  async onePolygonSelect(logSowing, polygonsSelect) {
    let filterSowing = logSowing.data
      .filter((el) =>
        el.polygons.some((polygonId) => polygonsSelect.includes(polygonId))
      )
      .sort(
        (a, b) =>
          new Date(a.date + "T00:00:00") - new Date(b.date + "T00:00:00")
      )
      .pop();

    if (filterSowing?.id) {
      if (filterSowing.crop !== null) {
        await axios
          .get(
            `https://agrodigital.io/api/crops/${filterSowing.crop}`,
            this.state.tokenUser
          )
          .then((crops) => {
            this.setState({ crop: crops.data });
          });
      }
      if (filterSowing.variety !== null) {
        await axios
          .get(
            `https://agrodigital.io/api/varieties/${filterSowing.variety}`,
            this.state.tokenUser
          )
          .then((varieties) => {
            this.setState({
              variety: varieties.data,
              checkVarietiesStatus: true,
            });
          });
      }
      this.setState({
        loadingLogs: true,
      });
    } else {
      this.setState({
        validatePerformance: true,
      });
    }
  }

  async multiPolygonsSelect(polygonsSelect, logSowing) {
    //meti en cada uno la informacion del registro para cada poligono
    let filterSowingForPolygon = logSowing.data.filter((logSow) =>
      polygonsSelect.some((select) => logSow.polygons.includes(select))
    );
    //utilizo para saber cuantos registros tiene cada poligono y lo ordeno por fecha, para despues saber si hay algun poligono sin fecha
    let sowingForPolygon = {};
    for (let k = 0; k < polygonsSelect.length; k++) {
      const polSelect = polygonsSelect[k];
      sowingForPolygon[polSelect] = [];
      for (let i = 0; i < filterSowingForPolygon.length; i++) {
        const filterSowing = filterSowingForPolygon[i];
        if (filterSowing.polygons.includes(polSelect)) {
          let arrSort = [...sowingForPolygon[polSelect], filterSowing].sort(
            (a, b) =>
              new Date(b.date + "T00:00:00") - new Date(a.date + "T00:00:00")
          );
          sowingForPolygon[polSelect] = arrSort;
        }
      }
    }
    let validSowingData = Object.values(sowingForPolygon).filter(
      (el) => el.length > 0
    );
    if (filterSowingForPolygon.length > 0) {
      //si tienen la misma cantidad significa que en los poligonos seleccionados todos tienen registro de siembra
      if (polygonsSelect.length === validSowingData.length) {
        let valuesSowing = validSowingData.map((el) => el[0]);
        this.checkCropsAndVarities(valuesSowing);
      } else {
        this.setState({
          validateHaveSowing: true,
        });
      }
    } else {
      this.setState({
        validatePerformance: true,
      });
    }
  }

  async checkCropsAndVarities(valuesSowing) {
    //todos los elementos del array tienen que tener el mismo crop
    let polygonSowingCrop = valuesSowing[0].crop;
    let checkCrop = valuesSowing.every(
      (list) => list.crop === polygonSowingCrop
    );
    //Chequear que todos tengan la misma
    let filterVarieties = valuesSowing.filter((el) => el.variety !== null);
    let checkVarieties = filterVarieties.length === valuesSowing.length;
    //todos tienen crop pero pueden tener diferente variety, por lo tanto hay que juntarla y pushearla
    //no importa cual sea por que todos van a tener el mismo crop si entran a este if
    if (checkCrop) {
      let cropData = valuesSowing[0];
      if (cropData.crop !== null) {
        await axios
          .get(
            `https://agrodigital.io/api/crops/${cropData.crop}`,
            this.state.tokenUser
          )
          .then((crops) => {
            this.setState({ crop: crops.data });
          });
      }
      this.setState({
        loadingLogs: true,
      });
    } else {
      this.setState({
        loadingLogs: false,
      });
    }

    if (checkVarieties) {
      let objVariety = filterVarieties[0].variety;
      let validadtionAux = filterVarieties.every(
        (el) => el.variety === objVariety
      );

      if (validadtionAux) {
        Promise.all(
          filterVarieties.map((el) =>
            axios.get(
              `https://agrodigital.io/api/varieties/${el.variety}`,
              this.state.tokenUser
            )
          )
        ).then((resData) => {
          let nameVariety = resData[0].data;
          this.setState({
            variety: nameVariety,
            checkVarietiesStatus: validadtionAux,
          });
        });
      } else {
        this.setState({ variety: null, checkVarietiesStatus: validadtionAux });
      }
    }
  }

  handlePerformanceChange(e) {
    var performanceValue = parseFloat(e.target.value, 10)
      ? parseFloat(e.target.value, 10)
      : null;
    if (performanceValue < 0 || performanceValue === null) {
      this.setState({
        performanceInvalid: true,
        performanceValue: performanceValue,
      });
    } else {
      this.setState({
        performanceValue: performanceValue,
        performanceInvalid: false,
      });
    }
  }

  saveButtonTapped = async () => {
    let vm = this;
    let performanceData = {};
    let dateString = this.state.harvest_date.toString();

    if (dateString.length === 10) {
      performanceData.date = moment(new Date(dateString + "T00:00:00")).format(
        "YYYY-MM-DD"
      );
    }
    if (dateString.length > 10) {
      performanceData.date = moment(new Date(this.state.harvest_date)).format(
        "YYYY-MM-DD"
      );
    }
    performanceData.value = parseFloat(this.state.performanceValue);
    performanceData.type = "Yield";
    performanceData.obs = this.state.observation;

    let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );

    this.setState({
      loading: true,
    });

    if (polygonsIds.length > 0 && this.props.fieldId) {
      this.setState({
        loading: false,
      });

      if (this.state.selectedLog !== null) {
        axios
          .get(
            `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
            this.state.tokenUser
          )
          .then((res) => {
            let data = {};
            for (let i = 0; i < res.data.length; i++) {
              const logReference = res.data[i];
              if (logReference.id === this.state.selectedLog.id) {
                data = logReference;
              }
            }
            axios
              .patch(
                `https://agrodigital.io/api/logs/${data.id}/`,
                performanceData,
                this.state.tokenUser
              )
              .then((res) => {
                vm.setState({
                  loading: false,
                });
                vm.closeButtonTapped(true);
              });
          });
      } else {
        let variety = this.state.variety?.id;
        performanceData.crop = this.state.crop.id;
        if (variety) {
          performanceData.variety = variety;
        } else {
          performanceData.variety = null;
        }
        performanceData.polygons = polygonsIds;
        axios
          .post(
            `https://agrodigital.io/api/logs/`,
            performanceData,
            this.state.tokenUser
          )
          .then(() => {
            vm.setState({
              loading: false,
            });
            vm.closeButtonTapped(true);
          });
      }
    }
  };

  clearState() {
    this.setState({
      harvest_date: null,
      performanceValue: null,
      performanceInvalid: null,
      validatePerformance: false,
      validateHaveSowing: false,
      loadingLogs: null,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      observation: "",
    });
  }

  closeButtonTapped(success = false) {
    this.clearState();
    this.props.onClose(success);
  }

  buttonDisabled() {
    if (
      this.state.validatePerformance === false &&
      this.state.loadingLogs === true &&
      this.state.validateHaveSowing === false &&
      this.state.harvest_date &&
      this.state.performanceValue
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {t}= this.props;
    return (
      <>
        <Modal isOpen={this.props.isOpen}>
          <div
            className="modal-header justify-content-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h4 className="title title-up">{t("globals.yield")}</h4>
          </div>
          <div className="modal-body">
            {this.state.alertMoreThanOnePolygon &&
              this.state.selectedLog?.id &&
              this.state.nameOfAllPolygons.length > 1 && (
                <AlertMultiPolygons
                  nameOfAllPolygons={this.state.nameOfAllPolygons}
                />
              )}
            <br />
            {/* SEGUIR ACA */}
            {this.state.loadingLogs === true && !this.props.selectedLog?.id && (
              <>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  {t("globals.lastSowing")}
                </p>
                <div style={{ display: "block", textAlign: "center" }}>
                  <br />
                  {this.state.crop && <p>{t("globals.crop")}: {this.state.crop.name}</p>}

                  {this.state.checkVarietiesStatus === true && (
                    <p> {t("globals.variety")}: {this.state.variety.name}</p>
                  )}
                  {this.state.checkVarietiesStatus === false && (
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                     {t("globals.increaseDifferentVarieties")}
                    </p>
                  )}
                </div>
                <hr />
              </>
            )}
            {this.state.loadingLogs === false && (
              <>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                {t("globals.moreVarieties")}
                </p>
                <hr />
              </>
            )}
            {this.state.validatePerformance === true && (
              <>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {t("yieldForm.loadSowingPrevYield")}
                </p>
                <hr />
              </>
            )}
            {this.state.validateHaveSowing === true && (
              <>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  {t("yieldForm.polygonsWithoutSowing")}
                </p>
                <hr />
              </>
            )}
            <Row className="justify-content-center">
              <Col sm={12} md={10} lg={8}>
                {
                  <FormGroup>
                    <label>{t("globals.yieldLabelText")}</label>
                    <Input
                      type="number"
                      name="performance"
                      value={this.state.performanceValue}
                      onChange={(e) => {
                        this.handlePerformanceChange(e);
                      }}
                      invalid={this.state.performanceInvalid}
                    />
                    <FormFeedback invalid>
                    {t("globals.numberCannotNegative")}
                    </FormFeedback>
                  </FormGroup>
                }
              </Col>
              <Col sm={12} md={10} lg={8}>
                <FormGroup>
                  <label>{t("globals.haverstDate")}</label>
                  <ReactDatetime
                    dateFormat="DD/MM/YYYY"
                    value={this.state.harvest_date}
                    onChange={(val) => {
                      this.setState({
                        harvest_date: val,
                      });
                    }}
                    inputProps={{
                      className: "form-control",
                      placeholder: t("globals.selectHaverstDate"),
                    }}
                    timeFormat={false}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={10} lg={8}>
                <FormGroup>
                  <label>{t("globals.observation")}</label>
                  <Input
                    type="text"
                    value={this.state.observation}
                    onChange={(e) =>
                      this.setState({
                        observation: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          {!this.state.loading ? (
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className="btn-link"
                  color="danger"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.closeButtonTapped()}
                >
                 {t("buttons.cancelButton")}
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  className="btn-link"
                  color="primary"
                  type="button"
                  disabled={this.buttonDisabled()}
                  onClick={() => this.saveButtonTapped()}
                >
                 {t("buttons.saveButton")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <CircularProgress color="secondary" />
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default YieldForm;
