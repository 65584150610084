import LoadingOverlay from "react-loading-overlay";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
// // react components used to create a google map
import * as moment from "moment";

// reactstrap components
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geotiff-2";
import { plot as Plot, addColorScale } from "plotty";
// optional renderers

import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty";
import "leaflet-geotiff-2/dist/leaflet-geotiff-rgb";

import SidebarLayer from "../../components/FixedPlugin/SidebarLayer.js";
import FixedPluginH from "../../components/FixedPlugin/FixedPluginH.js";

import "leaflet-measure/dist/leaflet-measure.css";
import "../../assets/css/leaflet-measure.css";
import "../../assets/css/rasters.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "reactstrap/es/Modal";
import axios from "axios";
import DeleteRasterModal from "./raster/DeleteRasterModal.js";
import { useContext } from "react";
import SourceProvider from "providers/SourceProvider";
import { useTranslation } from "react-i18next";
import { downloadRasterUrl } from "./raster/downloadRasterUrl.js";

var renderer;
var centered = false;

var map;
var xmlDocument= XMLDocument;

var objetos = {
  valores_slider_NDRE:[0, 0.125, 0.5, 1, 0, 0.8],
  valores_slider_NDVI: [0, 0.125, 0.5, 1, 0, 0.8],
  valores_slider_CCCI: [0, 0.125, 0.5, 1, 0, 0.8],
  valores_slider_NDWI: [0, 0.125, 0.5, 1, -1, 0.6],
  valores_slider_RGB: [0, 0.125, 0.5, 1, 0, 0.8],
};

const RasterMap = (props) => {
  const [t, i18n] = useTranslation("global")
  const optionsAux =[
    {
      value: "RGB",
      label: t("globals.visualRasterOption"),
    },
    {
      value: "NDVI",
      label: t("globals.greenIndexRasterOption"),
    },
    {
      value: "NDWI",
      label: t("globals.waterIndexRasterOption"),
    },
    {
      value: "CCCI",
      label: t("globals.icRasterOption"),
    },
    {
      value: "NDRE",
      label: "Red Edge Index (NDRE)",
    },
  ]
  const { planPlanet, planBasic, changePlan, changeDisabledButton } =
    useContext(SourceProvider);
  const [waypointDescription, setWaypointDescription] = useState("");
  const [rasterPlanBasic, setRasterPlanBasic] = useState([]);
  const [loadRasterData, setLoadRasterData] = useState(false);
  const [downloadKml, setDownloadKml] = useState(false);
  const [measurePoints, setMeasurePoints] = useState([]);
  const [optionSidebarLayer, setOptionSidebarLayer] = useState([]);
  const [measureList, setMeasureList] = useState([]);
  const [rasterPlanPlanet, setRasterPlanPlanet] = useState([]);
  const [state, setState] = useState({
    tokenUser: {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    },
    dateList: ["2018-01-01", "2020-12-30"],
    polygonsList: [],
    type: "RGB",
    renders: [],
    valores_slider: [0, 0.125, 0.5, 1],
    min: 0,
    max: 1,
    done: false,
    fecha: null,
    colors: ["#444444", "#d7191c", "#f5b60f", "#157a33"],
    colors_NDVI: ["#444444", "#d7191c", "#f5b60f", "#157a33"],
    colors_NDWI: ["#000", "#d7191c", "#ed0", "#08d"],
    colors_CCCI: ["#444444", "#05a", "#f5b60f", "#008a00"],
    colors_NDRE: ["#444444", "#d7191c", "#f5b60f", "#157a33"],
    fieldId: "",
    fieldInfo: null,
    showWaypointModal: false,
    rasterDeleteInfo: {},
    oneRasterDelete: false,
    showDeleteRasterModal: false,
    waypointCoord: [],
    waypoints: [],
    sourcePolygon: [],
    loading: false,
    cursorToggled: false,
    rgbSelect: false,
    rasterDate: [],
    firstRasterData: [],
    searchIndex: null,
    rasterSearch: false,
    dataLength: false,
    noDataRasterCCCI: false,
    noDataRasterNDWI: false,
    markers: null,
  });
  const { handleZip } = downloadRasterUrl();

  useEffect(() => {
    if (state.rasterSearch) {
      setState((prev) => ({
        ...prev,
        rasterSearch: false,
      }));
    }
  }, [state.rasterSearch]);
  useEffect(() => {
    const {
      match: { params },
    } = props;
    let fieldId = params.id;
    var container = L.DomUtil.get("map");
    if (container != null) {
      container._leaflet_id = null;
    }
    map = L.map("map", {
      center: [-32, -57],
      zoom: 7,
    });
    function getlangMeasure() {
      let lang = window.navigator.language.substring(0,2) === "es" ? "es" : "en";
      return import(`leaflet-measure/dist/leaflet-measure.${lang}.js`)
        .then((res) => {
          map.createPane("wms");
          map.getPane("wms").style.zIndex = 0;

          L.tileLayer("http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}", {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          }).addTo(map);

          map.createPane("raster");
          map.getPane("raster").style.zIndex = 300;
          map.createPane("polygons");
          map.getPane("polygons").style.zIndex = 200;

          addColorScale(
            "oryzacolor",
            state.colors.slice(0, 4),
            state.valores_slider
          );
          let measureOptions = {
            position: "bottomright",
            primaryLengthUnit: "meters",
            secondaryLengthUnit: "kilometers",
            primaryAreaUnit: "hectares",
            secondaryAreaUnit: undefined,
            activeColor: "#76ff08",
            completedColor: "#C8F2BE",
            captureZIndex: 100000,
            popupOptions: {
              className: "orderLinks leaflet-measure-resultpopup",
              autoPanPadding: [10, 10],
            },
          };

          let measureControl = new L.Control.Measure(measureOptions);
          measureControl.addTo(map);

          if (!localStorage.getItem("token")) {
            // props.history.push("/auth/login");
          } else {
            if (localStorage.getItem("token"))
              setState((prev) => ({
                ...prev,
                markers: L.layerGroup(),
                fieldId: fieldId,
              }));
          }
        })
        .catch((err) => "error");
    }

    getlangMeasure();
    map?.on("measurefinish", function (evt) {
      if (evt.points.length) {
        setDownloadKml(true);
        let measurePositions = measurePoints
        measurePositions.push(evt.points)
        setMeasureList(measurePositions)
        setMeasurePoints(evt.points);
      }
    });
  }, []);

  const button = document.createElement("button");
  button.classList.add("buttonDownloadKml");
  const node = document.createTextNode(t("globals.downloadKml"));
  button.appendChild(node);
  button?.addEventListener("click", function (evt) {
    console.log(evt.srcElement);
    createAndDownloadKML(measureList, evt);
    setDownloadKml(false);
  });
  useEffect(() => {
    // CUANDO DEJA EL MARCADO
    if (measurePoints.length > 0) {
      const popup = document.getElementsByClassName(
        "leaflet-popup-content"
      )[0];
      popup?.appendChild(button);
      let figure = document.getElementsByClassName("layer-measure-resultarea")
      let lastFigure = figure[figure.length - 1]
      console.log(lastFigure);
      console.log(figure);
      if(lastFigure){
      button.classList.add(figure.length - 1);
      // lastFigure.classList.add(figure.length - 1);
        addEventInFigure(lastFigure)
      }
    }
  }, [measurePoints.length]);

  // CUANDO HACE CLICK NUEVAMENTE EN LA FIGURA
  const addEventInFigure = (figure) => {
    if(figure)
    figure?.addEventListener("click", function (evt) {
     setTimeout(()=>{
      const popup = document.getElementsByClassName(
        "leaflet-popup-content"
      )[0];
      popup?.appendChild(button);
     }, 500)
    })
  }

 

  const createAndDownloadKML = (coordinates, element) => {
    let coords = coordinates
    let elementArray = element.srcElement.className.split(" ")
    let elementPosition = elementArray[elementArray.length - 1]
    let coord= coords[elementPosition]?.map((el) => ({ lat: el.lat, lng: el.lng }));
    const textXML = createKMLFileFromCoordinates(coord);
    download("Area-Oryzativa.kml", textXML);
  };

  const download = (filename, xmlDocument) => {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(xmlDocument)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    setMeasurePoints([]);
  };

  const createKMLFileFromCoordinates = (coordinates) => {
    xmlDocument = document.implementation.createDocument("", "", null);
    const kmlNode = xmlDocument.createElement("kml");
    kmlNode.setAttribute("xmlns", "http://www.opengis.net/kml/2.2");
    kmlNode.setAttribute("xmlns:kml", "http://www.opengis.net/kml/2.2");
    kmlNode.setAttribute("xmlns:gx", "http://www.google.com/kml/ext/2.2");
    kmlNode.setAttribute("xmlns:atom", "http://www.w3.org/2005/Atom");
    const placemarkNode = xmlDocument.createElement("Placemark");
    // COLOR LINEA DIBUJADA
    const styleNode = xmlDocument.createElement("Style");
    const lineStyleNode = xmlDocument.createElement("LineStyle");
    const colorNode = xmlDocument.createElement("color");
    const colorLines = document.createTextNode("ff0000ff");
    colorNode.appendChild(colorLines);
    // OPACIDAD DEL FONDO DEL POLIGONO DIBUJADO
    const polyStyleNode = xmlDocument.createElement("PolyStyle");
    const fillNode = xmlDocument.createElement("fill");
    const fillValue = document.createTextNode("0");
    polyStyleNode.appendChild(fillNode);
    fillNode.appendChild(fillValue);

    // AÑADIMOS LOS ESTILOS A PLACEMARK TAG
    kmlNode.appendChild(placemarkNode);
    lineStyleNode.appendChild(colorNode);
    placemarkNode.appendChild(styleNode);
    styleNode.appendChild(lineStyleNode);
    styleNode.appendChild(polyStyleNode);

    xmlDocument.appendChild(kmlNode);
    placemarkNode.appendChild(createPointNode(1, coordinates));
    return xmlDocumentToString(xmlDocument);
  };

  const xmlDocumentToString = (xmlDocument) => {
    let textXML = new XMLSerializer().serializeToString(xmlDocument);
    textXML = '<?xml version="1.0" encoding="UTF-8"?>' + textXML;
    return textXML;
  };

  const createPointNode = (name, coordinates) => {
    let coordNumbers = coordinates.map((el) => [el.lat, el.lng, 0]);
    let msgCoords = "";
    for (let i = 0; i < coordNumbers.length; i++) {
      const coord = coordNumbers[i];
      msgCoords += `${coord[1]},${coord[0]},0 `;
    }
    const polygonNode = xmlDocument.createElement("Polygon");
    const outerBoundaryIsNode = xmlDocument.createElement("outerBoundaryIs");
    const linearRingNode = xmlDocument.createElement("LinearRing");
    const coordinatesNode = xmlDocument.createElement("coordinates");
    coordinatesNode.innerHTML = msgCoords;
    polygonNode.appendChild(outerBoundaryIsNode);
    outerBoundaryIsNode.appendChild(linearRingNode);
    linearRingNode.appendChild(coordinatesNode);
    return polygonNode;
  };
  useEffect(() => {
    if (state.markers && state.fieldId) {
      loadFirstRasterData();
      loadWaypoints();
      loadFieldReport();
      fieldInfoUser();
    }
  }, [state.markers, state.fieldId]);

  useEffect(() => {
    if(loadRasterData)
      if (planBasic || planPlanet) {
        if(planBasic){
          checkDataNDRE()
        }
        if(planPlanet){
          setOptionSidebarLayer(optionsAux)
        }
        userPlanSource(true);
      } else {
        userPlanSource(false);
      }
  }, [planBasic, planPlanet, loadRasterData]);

  useEffect(() => {
    if (state.cursorToggled) {
      map.on("click", (e) => {
        setWaypontCoord(e);
      });
    } else {
      map.off("click");
    }
  }, [state.cursorToggled]);

  const checkDataNDRE= ()=>{
    let checkNDRE = rasterPlanBasic.filter((el)=> el.type === "NDRE")
    if(!checkNDRE.length){
    let filterWithoutNDRE = optionsAux.filter((el)=> el.value !== "NDRE")
    setOptionSidebarLayer(filterWithoutNDRE)
    }
  }

  const loadFirstRasterData = async () => {
  // SD
    let rasterPlanetPlan = await axios.get(
      `https://agrodigital.io/api/rasters/?field=${state.fieldId}&sources[]=PLA&sources[]=PLM`,
      state.tokenUser
    );
    // HD
    let rasterBasicPlan = await axios.get(
      `https://agrodigital.io/api/rasters/?field=${state.fieldId}&sources[]=LA9&sources[]=LA8&sources[]=SE2`,
      state.tokenUser
    );
    setRasterPlanBasic(rasterBasicPlan.data);
    setRasterPlanPlanet(rasterPlanetPlan.data);

    if (!rasterBasicPlan.data.length && !rasterPlanetPlan.data.length) {
      loadPolygons([], null, false);
      changePlan("restart", false);
    }
    if (rasterBasicPlan.data.length > 0 && rasterPlanetPlan.data.length > 0) {
      changeDisabledButton("basic", false);
      changeDisabledButton("planet", false);
      if (!planBasic) changePlan("planet", true);
      setLoadRasterData(true)
    } else {
      if (rasterBasicPlan.data.length > 0) {
        changePlan("basic", true);
        changeDisabledButton("basic", false);
        setLoadRasterData(true)
        
      } else {
        changeDisabledButton("basic", true);
      }
      if (rasterPlanetPlan.data.length > 0) {
        changePlan("planet", true);
        changeDisabledButton("planet", false);
        setLoadRasterData(true)
      } else {
        changeDisabledButton("planet", true);
      }
    }
  };
  const userPlanSource = async () => {
    setState((prev) => ({
      ...prev,
      done: false,
    }));
    if (planBasic) {
      loadPolygons(rasterPlanBasic, null, false);
    }
    if (planPlanet) {
      loadPolygons(rasterPlanPlanet, null, false);
    }
  };
  const loadPolygons = async (first_rasterData, indexRaster = null, deleteProcess = false) => {
    if (deleteProcess === false) {
      const fieldData = await axios.get(
        `https://agrodigital.io/api/polygons/?field=${state.fieldId}`,
        state.tokenUser
      );
      let polygons = fieldData.data.map((item) => {
        let tempData = item;
        tempData["id"] = item.id;
        if (tempData.deleted === null) {
          return tempData;
        }
      });
      let datesArray2 = [];
      if (first_rasterData.length === 0) {
        setState((prev) => ({
          ...prev,
          polygonsList: polygons,
          dateList: [],
          done: true,
        }));
        centerMap(polygons);
        drawPoligons(polygons, false);
      } else {
        let filterRasterForPolygons = first_rasterData.filter(
          (raster) =>
            raster.type === state.type &&
            polygons.some((el) => el.id === raster.polygon)
        );
        let getAllRastersData = first_rasterData.filter((raster) =>
          polygons.some((el) => el.id === raster.polygon)
        );
        let datesArray = filterRasterForPolygons.map((el) => el.date);

        let datesSort = datesArray.sort(
          (a, b) =>
            new Date(a + "T00:00:00").getTime() -
            new Date(b + "T00:00:00").getTime()
        );
        //array con indice cada fecha
        let indexDates = datesSort.filter((v, i, a) => a.indexOf(v) === i);
        var last;
        for (var i = 0; i < indexDates.length; i++) {
          last = moment(indexDates[i]);
          last.add(4, "hours");
          last.toDate().toISOString().substring(0, 10);
          datesArray2.push(last);
        }
        setState((prev) => ({
          ...prev,
          firstRasterData: getAllRastersData,
          rasterDate: datesSort,
          polygonsList: polygons,
          dateList: datesArray2,
          done: true,
        }));

        centerMap(polygons);
        drawPoligons(polygons, true);
        handleFecha([null, last], null, null, getAllRastersData, state.type);
      }
    } else {
      centerMap(state.polygonsList);
      drawPoligons(state.polygonsList, true);
      handleFecha(indexRaster, null, null, first_rasterData, state.type);
    }
  };

  const fieldInfoUser = async () => {
    let field = await axios.get(
      `https://agrodigital.io/api/fields/${state.fieldId}`,
      state.tokenUser
    );
    if (field.status === 200) {
      setState((prev) => ({ ...prev, fieldInfo: field.data }));
    }
  };
  const onUpdateModalToggle = (changeCursor) => {
    if (changeCursor) {
      L.DomUtil.removeClass(map._container, "marker-cursor-enabled");
    }
    setState((prev) => ({
      ...prev,
      showWaypointModal: changeCursor ? false : true,
      cursorToggled: changeCursor ? false : true,
    }));
  };
  const setWaypontCoord = (e) => {
    setState((prev) => ({
      ...prev,
      showWaypointModal: true,
      waypointCoord: [e.latlng.lat, e.latlng.lng],
    }));
  };

  //CHECK
  const centerMap = (polygons) => {
    if (polygons && polygons.length > 0) {
      let p1 = L.point(
        polygons[0].boundary.coordinates[0][0][1],
        polygons[0].boundary.coordinates[0][0][0]
      );
      let bounds = L.latLngBounds([p1, p1]);
      polygons.forEach((polygon) => {
        polygon.boundary.coordinates[0].forEach((coord) => {
          let p2 = L.latLng(coord[1], coord[0]);
          bounds.extend(p2);
        });
      });
      map.fitBounds(bounds);
      map.addLayer(state.markers);
    }
  };
  //CHECK
  const addWaypoint = () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    let waypointData = {
      obs: waypointDescription,
      loc: {
        type: "Point",
        coordinates: [state.waypointCoord[1], state.waypointCoord[0]],
      },
      field: state.fieldId,
    };
    axios
      .post(
        "https://agrodigital.io/api/waypoints/",
        waypointData,
        state.tokenUser
      )
      .then(function () {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));

        onUpdateModalToggle(true);
        loadWaypoints();
      })
      .catch(function (error) {
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        onUpdateModalToggle(true);
        console.error("Error writing document", error);
      });
  };

  const loadWaypoints = async () => {
    let waypoints = [];
    const waypointsData = await axios.get(
      `https://agrodigital.io/api/waypoints?field=${state.fieldId}`,
      state.tokenUser
    );
    waypointsData.data.map((item) => {
      let tempData = item;
      tempData["id"] = item.id;
      if (tempData.id) {
        waypoints.push(tempData);
      }
    });
    let redIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    for (var i = 0; i < waypoints.length; i++) {
      let waypoint = waypoints[i];
      var marker = L.marker([
        waypoint.loc.coordinates[1],
        waypoint.loc.coordinates[0],
      ], {icon: redIcon}).addTo(map);
      marker.bindPopup(`<b>${waypoint.obs}</b><br>`);
    }
  };
  const loadFieldReport = async () => {
    const fieldReport = await axios.get(
      `https://agrodigital.io/api/logs/?field=${state.fieldId}&type=FieldReport`,
      state.tokenUser
    );
    let fieldReportData = fieldReport.data.map((item) => {
      let tempData = item;
      tempData["id"] = item.id;
      if (tempData.id) {
        return tempData;
      }
    });
    for (var i = 0; i < fieldReportData.length; i++) {
      let report = fieldReportData[i];

      await axios
        .get(
          `https://agrodigital.io/api/field_var/${report.variable}`,
          state.tokenUser
        )
        .then((res) => {
          let dataVariable = res.data;
          if (report.loc) {
            let marker = L.marker([
              report.loc.coordinates[1],
              report.loc.coordinates[0],
            ]).addTo(map);
            let checkValue= report.value ? `<p>${t("globals.value")}: ${report.value} ${dataVariable.unit}</p>`  :""
            if (report.image) {
              marker.bindPopup(
                `
            <div class="div-popup-image">
            <h6 class="center">${dataVariable.name}</h6>
            ${checkValue}
            <a href=${report.image} target="_blank"> 
            <img src=${report.image} alt="Oryzativa"/>
            </a>
            <p>${t("globals.date")}: ${moment(report.date).format("DD-MM-YYYY")}</p>
            <p>${report.obs && `${t("globals.observation")}: ${report.obs}`}</p>
            </div>
            `,
                { maxWidth: 500 }
              );
            } else {
              marker.bindPopup(`
              <div class="div-popup-image">
              <h6 class="center">${dataVariable.name}</h6>
              ${checkValue}
              <p>${t("globals.date")}: ${moment(report.date).format("DD-MM-YYYY")}</p>
              <p>${report.obs && `${t("globals.observation")}: ${report.obs}`}</p>
            </div>
            `);
            }
          }
        });
    }
  };

  //CHECK
  const drawPoligons = (polygons, dataLength) => {
    for (var i = 0; i < polygons.length; i++) {
      let polygon = polygons[i];
      var polygonOptions = {
        id: polygon.id,
        name: polygon.name,
        key: i,
        color: "#aaffaa",
        weight: 1.0,
        pane: "polygons",
      };
      var polygonCoords = getPolygons(polygon);
      let discardRasterListener = L.polygon(polygonCoords, polygonOptions);
      //Menu opciones
      if (dataLength) {
        discardRasterListener.on("contextmenu", (e) => {
          setState((prev) => ({
            ...prev,
            oneRasterDelete: true,
            rasterDeleteInfo: e.target.options,
            showDeleteRasterModal: true,
          }));
        });
      }
      discardRasterListener.addTo(map);
    }
  };

  //Controla los colores de la sidebarLayer
  //CHECK

  const handleColors = (colors) => {
    let valores=[]
    var amplitud = colors._60 - colors._0;
    var d1 = (colors._20 - colors._0) / amplitud;
    var d2 = (colors._40 - colors._0) / amplitud;
    if(state.type === "CCCI"){
      valores =  [0, d1 / 10, d2 / 10, amplitud / 10];
    } else{
      valores = [0, d1, d2, 1];
    }

    var key = "valores_slider_" + state.type;
    objetos[key] = valores;
    objetos[key][4] = colors._0;
    objetos[key][5] = colors._60;
    addColorScale("oryzacolor", state.colors.slice(0, 4), valores.slice(0, 4));
    if (state.type == "NDWI") {
      state.renders.forEach(function (renderer) {
        renderer.setDisplayRange(2 * colors._0 - 1, 2 * colors._60 - 1);
        renderer.setClamps(false, true);
      });
      setState((prev) => ({
        ...prev,
        valores_slider: valores,
        min: 2 * colors._0 - 1,
        max: 2 * colors._60 - 1,
      }));
    } else {
      state.renders.forEach(function (renderer) {
        renderer.setDisplayRange(colors._0, colors._60);
        renderer.setClamps(false, true);
      });
      setState((prev) => ({
        ...prev,
        valores_slider: valores,
        min: colors._0,
        max: colors._60,
      }));
    }
  };

  const changeIndex = (index) => {
    let toasId = toast.info(t("globals.loadIndex"));
    if (index.value == "RGB") {
      //OJO CON EL SETsTATE
      setState((prev) => ({
        ...prev,
        type: index.value,
        rgbSelect: true,
        noDataRasterCCCI: false,
        noDataRasterNDWI: false,
      }));
      handleFecha(state.fecha, null, null, state.firstRasterData, index.value);
    } else {
      setState((prev) => ({
        ...prev,
        rgbSelect: false,
        noDataRasterCCCI: false,
        noDataRasterNDWI: false,
      }));
      var colores = state["colors_" + index.value];
      var key = "valores_slider_" + index.value;
      addColorScale(
        "oryzacolor",
        colores.slice(0, 4),
        objetos[key].slice(0, 4)
      );
      if (index.value == "NDWI") {
        //OJO CON ESTE SETsTATE
        setState((prev) => ({
          ...prev,
          type: index.value,
          colors: colores,
          valores_slider: objetos[key].slice(0, 4),
          min: 2 * objetos[key][4] - 1,
          max: 2 * objetos[key][5] - 1,
        }));
        handleFecha(
          state.fecha,
          2 * objetos[key][4] - 1,
          2 * objetos[key][5] - 1,
          state.firstRasterData,
          index.value
        );
      } else {
        //OJO CON ESTE SETsTATE

        setState((prev) => ({
          ...prev,
          type: index.value,
          colors: colores,
          valores_slider: objetos[key].slice(0, 4),
          min: objetos[key][4],
          max: objetos[key][5],
        }));

        handleFecha(
          state.fecha,
          objetos[key][4],
          objetos[key][5],
          state.firstRasterData,
          index.value
        );
      }
    }
  };

  //CHECK
  //Aca tenia un error de filtrado en las fechas
  const handleFecha = async (index, min, max, rasterData, type) => {
    if (min == null) {
      min = state.min;
    }
    if (max == null) {
      max = state.max;
    }
    var fecha = index[1];

    var tipo = type;
    if (tipo === "RGB") {
      setState((prev) => ({ ...prev, rgbSelect: true }));
    } else {
      setState((prev) => ({ ...prev, rgbSelect: false }));
    }
    let m = moment(fecha);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let desde = m.toDate().getTime();
    m.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
    let hasta = m.toDate().getTime();
    let mapa = map;
    map.eachLayer(function (layer) {
      if (layer.raster) {
        mapa.removeLayer(layer);
      }
    });
    state.markers.clearLayers();

    let filterRastersTypeAndPolygon = rasterData.filter(
      (polygonRaster) => polygonRaster.type === tipo
    );
    //cada vez que cambie el tipo va a restaurar las fechas que tengan
    let datesArray = filterRastersTypeAndPolygon.map((el) => el.date);

    let datesSort = datesArray.sort(
      (a, b) =>
        new Date(a + "T00:00:00").getTime() -
        new Date(b + "T00:00:00").getTime()
    );
    //Busca fecha exacta
    let filterDate = filterRastersTypeAndPolygon.filter(
      (polygonRaster) =>
        new Date(polygonRaster.date + "T00:00:00").getTime() >= desde &&
        new Date(polygonRaster.date + "T00:00:00").getTime() <= hasta
    );

    //todas las fechas formateadas con moment
    let datesArray2 = [];
    let indexDates = datesSort.filter((v, i, a) => a.indexOf(v) === i);
    var last;
    for (var i = 0; i < indexDates.length; i++) {
      last = moment(indexDates[i]);
      last.add(4, "hours");
      last.toDate().toISOString().substring(0, 10);
      datesArray2.push(last);
    }

    //busca el indice de la fecha acordada
    let searchIndexDate = indexDates.findIndex(
      (el) => el === moment(index[1]).format("YYYY-MM-DD")
    );

    if (filterDate.length > 0) {
      addRasterInfoToMap(
        filterDate,
        datesSort,
        datesArray2,
        index,
        searchIndexDate,
        tipo,
        min,
        max
      );
    } else {
      //si no encontro el indice se busca la ultima fecha disponible de los rasterData
      let allDates = filterRastersTypeAndPolygon.filter(
        (polygonRaster) =>
          new Date(polygonRaster.date + "T00:00:00").getTime() <= hasta
      );
      if (allDates.length > 0) {
        let searchNearDate = allDates[allDates.length - 1];
        let m = moment(searchNearDate.date);
        m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let date = m.toDate().getTime();
        let searchNewIndex = indexDates.findIndex(
          (el) => el === moment(searchNearDate?.date).format("YYYY-MM-DD")
        );
        let searchDate = allDates.filter(
          (polygonRaster) =>
            new Date(polygonRaster.date + "T00:00:00").getTime() >= date &&
            new Date(polygonRaster.date + "T00:00:00").getTime() <= date
        );
        addRasterInfoToMap(
          searchDate,
          datesSort,
          datesArray2,
          [null, moment(searchNearDate?.date)],
          searchNewIndex,
          tipo,
          min,
          max
        );
      } else {
        //si no hay directamente nada de nada se da aviso
        if (tipo === "CCCI") {
          setState((prev) => ({
            ...prev,
            noDataRasterCCCI: true,
            noDataRasterNDWI: false,
          }));
        }
        if (tipo === "NDWI") {
          setState((prev) => ({
            ...prev,
            noDataRasterNDWI: true,
            noDataRasterCCCI: false,
          }));
        }
      }
    }
  };
  const addRasterInfoToMap = (
    dataInfo,
    datesSort,
    datesArray2,
    index,
    searchIndexDate,
    tipo,
    min,
    max
  ) => {
    let renders = [];
    dataInfo.forEach(function (ras) {
      if (tipo === "RGB") {
        var renderer = L.LeafletGeotiff.rgb({ bandMaxVal: 0.5 });
        const options2 = {
          transpValue: 0,
          renderer: renderer,
          pane: "raster",
        };
        L.leafletGeotiff(ras.url, options2).addTo(map);
      } else {
        var options = {
          displayMin: min,
          displayMax: max,
          clampLow: false,
          clampHigh: true,
          colorScale: "oryzacolor",
          noDataValue: 0,
        };
        var renderer = L.LeafletGeotiff.plotty(options);
        const options2 = {
          renderer: renderer,
          pane: "raster",
          transpValue: 0,
        };


        var raster_layer = L.leafletGeotiff(ras.url, options2);
        raster_layer.addTo(map);
      }
      renders.push(renderer);
    });
    setState((prev) => ({
      ...prev,
      rasterSearch: true,
      noDataRasterCCCI: false,
      noDataRasterNDWI: false,
      renders: renders,
      fecha: index,
      sourcePolygon: dataInfo,
      rasterDate: datesSort,
      dateList: datesArray2,
      done: true,
      searchIndex: searchIndexDate,
    }));
  };

  const getPolygons = (polygon) => {
    const coords = polygon.boundary.coordinates[0];
    let result = [];
    for (let i = 0; i < coords.length; i++) {
      let coord = coords[i];
      let temp = [];
      temp[0] = coord[1];
      temp[1] = coord[0];

      result.push(temp);
    }

    return result;
  };

  const toggleCursor = () => {
    L.DomUtil.addClass(map._container, "marker-cursor-enabled");
    setState((prev) => ({
      ...prev,
      cursorToggled: true,
    }));
  };

  const toggleDeleteRastersModal = (deleteAllRasters = false) => {
    if (deleteAllRasters) {
      setState((prev) => ({
        ...prev,
        showDeleteRasterModal: !state.showDeleteRasterModal,
        oneRasterDelete: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        showDeleteRasterModal: !state.showDeleteRasterModal,
      }));
    }
  };

  const deleteRastersProcess = (status) => {
    if (status) {
      setState((prev) => ({
        ...prev,
        done: false,
        showDeleteRasterModal: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        done: false,
        showDeleteRasterModal: false,
      }));
    }
  };
  const restartDates = (
    removeRasterData,
    searchIndexDate,
    datesSort,
    datesArray2,
    indexDates
  ) => {
    //OJO CON ESTE SETsTATE
    setState((prev) => ({
      ...prev,
      rasterSearch: true,
      searchIndex: searchIndexDate,
      firstRasterData: removeRasterData,
      rasterDate: datesSort,
      dateList: datesArray2,
      done: true,
    }));

    if(planBasic){
      setRasterPlanBasic(removeRasterData)
    }
    if(planPlanet){
      setRasterPlanPlanet(removeRasterData)
    }
    loadPolygons(removeRasterData, [null, moment(indexDates[searchIndexDate])], true);
  };

  const downloadRastersUrl =()=>{
    let images = state.sourcePolygon.map((el)=> el.url)
    handleZip(images)
  }
  const waypointModal = (
    <Modal
      isOpen={state.showWaypointModal}
      keyboard={false}
      style={{ maxWidth: "600px", transform: "translate(0, 0)" }}
    >
      <div
        className="modal-header justify-content-center"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <h4 className="title title-up">{t("globals.addWaypoint")}</h4>
      </div>
      <div className="modal-body">
        <br />
        <Row className="justify-content-center">
          <Col sm={12} md={10} lg={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-air-baloon" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t("globals.textWaypoint")}
                type="text"
                value={state.polygonName}
                onChange={(e) => setWaypointDescription(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
      {!state.loading ? (
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => onUpdateModalToggle(true)}
            >
              {t("buttons.cancelButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => addWaypoint()}
            >
              {t("buttons.saveButton")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      )}
    </Modal>
  );
  return (
    <>
      {waypointModal}
      {state.showDeleteRasterModal &&
        state.fecha &&
        state.polygonsList.length > 0 && (
          <DeleteRasterModal
            planBasic={planBasic}
            planPlanet={planPlanet}
            fieldId={state.fieldId}
            tokenUser={state.tokenUser}
            isOpen={state.showDeleteRasterModal}
            oneRasterDelete={state.oneRasterDelete}
            loading={state.loading}
            rasterDeleteInfo={state.rasterDeleteInfo}
            fecha={state.fecha[1]}
            sourcePolygon={state.sourcePolygon}
            polygonsList={state.polygonsList}
            toggleDeleteRastersModal={toggleDeleteRastersModal}
            restartDates={restartDates}
            deleteRastersProcess={deleteRastersProcess}
          />
        )}
      <LoadingOverlay active={!state.done} spinner text={t("globals.loading")}>
        <SidebarLayer
          t={t}
          planPlanet={planPlanet}
          fieldInfo={state.fieldInfo}
          noDataRasterCCCI={state.noDataRasterCCCI}
          noDataRasterNDWI={state.noDataRasterNDWI}
          rgbSelect={state.rgbSelect}
          width={280}
          colors={state.colors}
          fieldId={state.fieldId}
          handleColors={handleColors}
          changeIndex={changeIndex}
          toggleCursor={toggleCursor}
          downloadRastersUrl={downloadRastersUrl}
          optionSidebarLayer={optionSidebarLayer}
        />
        <div>
          <div
            id="map"
            className="map-content"
            style={{
              height: "100vh",
              maxWidth: "calc(100% - 280px)",
              width: "auto",
            }}
          ></div>
        </div>
        <ToastContainer />
        <FixedPluginH
          rasterSearch={state.rasterSearch}
          searchIndex={state.searchIndex}
          toggleDeleteRastersModal={toggleDeleteRastersModal}
          title={t("globals.datesControl")}
          bgColor={state.backgroundColor}
          handleFecha={handleFecha}
          fechas={state.dateList}
          initial={state.dateList?.length - 1}
          firstRasterData={state.firstRasterData}
          type={state.type}
        />
      </LoadingOverlay>
    </>
  );
};

export default RasterMap;
