import axios from "axios";
import React from "react";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "reactstrap";
const StopShareModalDashboard = (props) => {
  const [t, i18n] = useTranslation("global");

  const stopShareField = async () => {
    let currentUser= props.currentUser
    let fieldInfo= props.shareFieldId
    let filterUsersAccess= fieldInfo.access?.filter((el)=> el != currentUser.id)

    await axios
    .patch(
      `https://agrodigital.io/api/fields/${fieldInfo.id}/`,
      { access: filterUsersAccess },
      props.tokenUser
    )
    .then(()=> {
      props.closeStopShareModal(false)
      toast.success(t("alerts.withoutAccessField"));
    })
  };

  return (
    <Modal isOpen={props.showStopShareModal}>
      <div className="modal-body" style={{textAlign:"center"}}>
        <Row>
          <Col sm={12}>
            <label>{t("globals.removeUserAccess")}</label>
          </Col>
        </Row>
      </div>
      {props.loadingShare ? (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => props.closeStopShareModal()}
            >
              {t("buttons.noButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className={"btn-link"}
              color="primary"
              type="button"
              onClick={() => {
                stopShareField();
              }}
            >
              {t("buttons.yesButton")}
            </Button>
          </div>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </Modal>
  );
};

export default StopShareModalDashboard;
