import React from "react";
import { useTranslation } from "react-i18next";
import "../../../assets/css/rasters.css"
const DeleteRasterInfo = () => {
  const [t, i18n]= useTranslation("global")
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#dee3ed",
          marginInline: "16px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          padding: "5px",
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/7193/7193032.png"
          style={{ height: "25px" }}
        />
        <h6 style={{ margin: "0", paddingLeft: "10px", color: "black" }}>
          {t("globals.removeClouds")}
        </h6>
      </div>
      <p className="rasterDeleteMsg"
        style={{
          backgroundColor: "#dee3ed",
          marginInline: "16px",
          color: "black",
          textAlign: "center",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          padding: "5px",
        }}
      >
          {t("globals.removeCloudsInfo")}
      </p>
    </>
  );
};

export default DeleteRasterInfo;
