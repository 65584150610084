import React, { Component } from "react";
import { FormGroup, Modal, Button, Table } from "reactstrap";
import { Log } from "../../Models/Logs.js";
import Select from "react-select";
import YieldForm from "./Forms/YieldForm.js";
import ApplicationForm from "./Forms/ApplicationForm.js";
import CreateAndEditSeedForm from "./Forms/CreateAndEditSeedForm.js";
import FieldDataForm from "./Forms/FieldDataForm.js";
import FertilizationForm from "./Forms/FertilizationForm.js";
import GenericDateAndSelectForm from "./Forms/GenericDateAndSelectForm.js";
import { withTranslation } from "react-i18next";

class CreateLogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      polygonsArray: [],
      showApplicationModal: false,
      showFieldDataModal: false,
      showFertilizationModal: false,
      showGenericDateAndSelectModal: false,
      showYieldForm: false,
      showSeedFormData: false,
      selectedLogType: null,
    };

    this.isPolygonChecked = this.isPolygonChecked.bind(this);
    this.onAddPolygon = this.onAddPolygon.bind(this);
    this.clearState = this.clearState.bind(this);
    this.fillDataTapped = this.fillDataTapped.bind(this);
    this.createLogData = {};
    this.logTypes = Log.getLogTypes(props);
    this.closeLogModal = this.closeLogModal.bind(this);
  }

  componentWillReceiveProps() {
    this.state = {
      polygonsArray: [],
      showApplicationModal: false,
      showFieldDataModal: false,
      showFertilizationModal: false,
      showGenericDateAndSelectModal: false,
      showYieldForm: false,
      showSeedFormData: false,
      selectedLogType: null,
    };
    this.createLogData = {};
  }

  onAddPolygon(polygon) {
    if (polygon.value) {
      if (this.isPolygonChecked(polygon)) {
        delete this.createLogData[polygon.value];
        this.setState({});
      } else {
        this.createLogData[polygon.value] = {};
        this.setState({});
      }
    }
  }

  isPolygonChecked(polygon) {
    if (polygon.value in this.createLogData) {
      return true;
    }
    return false;
  }

  clearState() {
    this.setState({
      polygonsArray: [],
      showApplicationModal: false,
      showFieldDataModal: false,
      showGenericDateAndSelectModal: false,
      showYieldForm: false,
      showSeedFormData: false,
      showFertilizationModal: false,
    });
    this.createLogData = {};
  }

  onCloseButtonTapped(success) {
    this.clearState();
    if (success) {
      this.props.onCreateSuccess();
    } else {
      this.props.onClose();
    }
  }

  changeLogType = (value) => {
    this.setState({
      selectedLogType: value,
      showFieldDataModal: false,
      showApplicationModal: false,
      showGenericDateAndSelectModal: false,
      showYieldForm: false,
      showSeedFormData: false,
      showFertilizationModal: false,
    });
  };

  fillDataTapped() {
    if (
      this.state.selectedLogType &&
      Object.keys(this.createLogData).length > 0
    ) {
      switch (this.state.selectedLogType.value) {
        case "Application":
          this.setState({
            showApplicationModal: true,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: false,
            showSeedFormData: false,
            showYieldForm: false,
            showFertilizationModal: false,
          });
          return;
        case "FieldReport":
          this.setState({
            showApplicationModal: false,
            showFieldDataModal: true,
            showGenericDateAndSelectModal: false,
            showSeedFormData: false,
            showYieldForm: false,
            showFertilizationModal: false,
          });
          return;
        case "Fertilization":
          this.setState({
            showApplicationModal: false,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: false,
            showSeedFormData: false,
            showYieldForm: false,
            showFertilizationModal: true,
          });
          return;
        case "Water":
        case "Phenology":
        case "LabReport":
          this.setState({
            showApplicationModal: false,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: true,
            showSeedFormData: false,
            showYieldForm: false,
            showFertilizationModal: false,
          });
          return;
        case "Sowing":
          this.setState({
            showApplicationModal: false,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: false,
            showSeedFormData: true,
            showYieldForm: false,
            showFertilizationModal: false,
          });
          return;
        case "Yield":
          this.setState({
            showApplicationModal: false,
            showFieldDataModal: false,
            showGenericDateAndSelectModal: false,
            showSeedFormData: false,
            showYieldForm: true,
            showFertilizationModal: false,
          });
          return;
        default:
          break;
      }
    }
  }

  closeLogModal(success) {
    if (
      this.state.selectedLogType &&
      Object.keys(this.createLogData).length > 0
    ) {
      this.setState(
        {
          showApplicationModal: false,
          showFieldDataModal: false,
          showGenericDateAndSelectModal: false,
          showSeedFormData: false,
          showYieldForm: false,
          showFertilizationModal: false,
        },
        () => {
          if (success) {
            this.props.onCreateSuccess();
          }
        }
      );
      return;
    }
  }

  getSelectedLogType() {
    if (this.state.selectedLogType) {
      return this.state.selectedLogType.value;
    }
    return null;
  }

  render() {
    const { t } = this.props;

    //  ESTOS SON PARA CREAR REGISTROS (POST)
    const fieldLogModal = (
      <FieldDataForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showFieldDataModal}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
        createLog={this.getSelectedLogType()}
      ></FieldDataForm>
    );
    const appLogModal = (
      <ApplicationForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showApplicationModal}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
      ></ApplicationForm>
    );

    const fertilizationModal = (
      <FertilizationForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showFertilizationModal}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
      ></FertilizationForm>
    );

    const genericDateAndSelectModal = (
      <GenericDateAndSelectForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showGenericDateAndSelectModal}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
        type={this.getSelectedLogType()}
      ></GenericDateAndSelectForm>
    );

    const seedForm = (
      <CreateAndEditSeedForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showSeedFormData}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
      ></CreateAndEditSeedForm>
    );

    const yieldForm = (
      <YieldForm
        t={t}
        userInfo={this.props.userInfo}
        isOpen={this.state.showYieldForm}
        selectedPolygons={this.createLogData}
        fieldId={this.props.fieldId}
        onClose={this.closeLogModal}
        selectedLog={null}
      ></YieldForm>
    );

    return (
      <>
        {appLogModal}
        {this.state.showFieldDataModal && fieldLogModal}
        {this.state.showFertilizationModal && fertilizationModal}
        {this.state.showGenericDateAndSelectModal && genericDateAndSelectModal}
        {this.state.showSeedFormData && seedForm}
        {this.state.showYieldForm && yieldForm}
        <Modal isOpen={this.props.isOpen}>
          <div
            className="modal-header justify-content-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h4 className="title title-up">{t("globals.addLog")}</h4>
          </div>
          <div className="modal-body">
            <br />
            <label className="text-center">
              {t("globals.selectPolygonsCreateLog")}
            </label>
            <br />
            <div style={{ height: "350px" }}>
              <Table responsive hover={true} className="detail-table">
                <thead className="text-primary">
                  <tr>
                    <th className="text-center">#</th>
                    <th>{t("globals.polygon")}</th>
                    <th>{t("globals.addLog")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.polygons && this.props.polygons.length > 0 ? (
                    this.props.polygons.map((polygon, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{polygon.label}</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            readOnly
                            checked={this.isPolygonChecked(polygon)}
                            onClick={() => this.onAddPolygon(polygon)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        style={{ textAlign: "center", fontSize: "1.8em" }}
                      >
                        {t("globals.noPolygonsInField")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div>
              <FormGroup>
                <label>{t("globals.logType")}</label>
                <Select
                  name="logType"
                  className="react-select"
                  placeholder={t("globals.selectLogType")}
                  classNamePrefix="react-select"
                  value={this.state.selectedLogType}
                  onChange={(value) => this.changeLogType(value)}
                  options={this.logTypes}
                />
              </FormGroup>
            </div>
          </div>
          {
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className="btn-link"
                  color="danger"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.onCloseButtonTapped(false)}
                >
                  {t("buttons.cancelButton")}
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  className="btn-link"
                  color="primary"
                  type="button"
                  onClick={() => this.fillDataTapped()}
                >
                  {t("buttons.insertDataButton")}
                </Button>
              </div>
            </div>
          }
        </Modal>
      </>
    );
  }
}
export default withTranslation("global")(CreateLogForm);
