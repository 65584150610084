import React, { useState } from "react";
import Modal from "reactstrap/es/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { max } from "moment";

const AppModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    };

  const showOnceADay = () => {
    const lastShown = localStorage.getItem("lastShown");
    const today = new Date().toISOString().slice(0, 12);
    if (lastShown !== today) {
        localStorage.setItem("lastShown", today);
        setIsOpen(true);
    }
    };

  const CustomArrow = ({ className, style, onClick, direction }) => (
    <button
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        color: "#fff",
        border: "none",
        zIndex: 1,
        position: "absolute",
        top: "50%",
        [direction === "next" ? "right" : "left"]: "10px",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
      onClick={onClick}
      aria-label={direction === "next" ? "Next slide" : "Previous slide"}
    >
      {direction === "next" ? "›" : "‹"}
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  };

  showOnceADay();

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        size="lg"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <div style={{ textAlign: "center", padding: "30px", position: "relative" }}>
          {/* Botón de cierre */}
          <button
            onClick={toggleModal}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              color: "#000",
              fontSize: "20px",
              cursor: "pointer",
            }}
            aria-label="Close"
          >
            ✖
          </button>

          <h2 style={{ marginBottom: "20px", color: "#333" }}>
            Nuevas funcionalidades en Oryzativa
          </h2>
          <p style={{ marginBottom: "20px", color: "#666" }}>
            Hemos incorporado nuevas funcionalidades en Oryzativa para ayudarte a gestionar 
            tus cultivos de arroz de forma más eficiente.
          </p>

          <Slider {...sliderSettings}>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0001.jpg"
                alt="Feature 1"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0002.jpg"
                alt="Feature 2"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0003.jpg"
                alt="Feature 3"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0004.jpg"
                alt="Feature 3"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0005.jpg"
                alt="Feature 3"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div>
              <img
                src="https://storage.googleapis.com/agrodigital/uploads/0006.jpg"
                alt="Feature 3"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </Slider>

          <button
            onClick={() => {
              window.location.href = `https://dev.oryzativa.com?token=${localStorage.getItem(
                "token"
              )}`;
            }}
            style={{
              marginTop: "20px",
              padding: "12px 24px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Probá la nueva versión
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AppModal;
