import React, { useEffect, useState } from "react";
import { Button, Table, UncontrolledTooltip } from "reactstrap";
import "../../assets/css/tableDashboard.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TableDashboard = (props) => {
  const [t, i18n] = useTranslation("global");
  const [selectedFarmOptions, setSelectedFarmOptions] = useState([]);
  const [selectedFieldInFarm, setSelectedFieldInFarm] = useState([]);
  const [selectedFieldNotFarm, setSelectedFieldNotFarm] = useState([]);
  const [optionsOtherFields, setOptionsOtherFields] = useState(true);
  const showShareModal = (field) => {
    props.setStateDashboard((prev) => ({
      ...prev,
      showShareModal: true,
      shareFieldId: field,
    }));
  };

  const showStopShareModal = (field) => {
    props.setStateDashboard((prev) => ({
      ...prev,
      showStopShareModal: true,
      shareFieldId: field,
    }));
  };
  const setBoundsTable = (fieldMap, isFarm) => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;
    let result = [
      [props.centerLocation.lng, props.centerLocation.lat],
      [props.centerLocation.lng, props.centerLocation.lat],
    ];
    let data = fieldMap;
    if (isFarm === -1) {
      for (let j = 0; j < data?.length; j++) {
        let coords = data[j]?.boundary.coordinates;
        if (coords) {
          for (let k = 0; k < coords.length; k++) {
            let coordinates = coords[k];
            for (let l = 0; l < coordinates.length; l++) {
              let latitude = coordinates[l][1];
              let longitude = coordinates[l][0];
              if (latitude > top) {
                top = coordinates[l][1];
              }

              if (latitude < bottom) {
                bottom = coordinates[l][1];
              }

              if (longitude > left) {
                left = longitude;
              }

              if (longitude < right) {
                right = longitude;
              }
            }
          }
        }
      }
      result = [
        [top, left],
        [bottom, right],
      ];
    } else {
      for (let i = 0; i < data?.length; i++) {
        let polygons = data[i]?.polygonData;
        for (let j = 0; j < polygons?.length; j++) {
          let coords = polygons[j]?.boundary.coordinates;
          if (coords) {
            for (let k = 0; k < coords.length; k++) {
              let coordinates = coords[k];
              for (let l = 0; l < coordinates.length; l++) {
                let latitude = coordinates[l][1];
                let longitude = coordinates[l][0];
                if (latitude > top) {
                  top = coordinates[l][1];
                }
                if (latitude < bottom) {
                  bottom = coordinates[l][1];
                }
                if (longitude > left) {
                  left = longitude;
                }

                if (longitude < right) {
                  right = longitude;
                }
              }
            }
          }
        }
      }
      result = [
        [top, left],
        [bottom, right],
      ];
    }
    props.setStateDashboard((prev) => ({
      ...prev,
      bounds: result,
      showLoading: false,
    }));
  };

  const MouseRowOver = (
    selected,
    fieldIdex = -1,
    polygonIndex = -1,
    farmerIndex = -1
  ) => {
    let selectedElement =
      farmerIndex !== -1 ? selected.fields : selected.polygonData;
    if (!props.showPerformanceForm && props._isMounted) {
      props.setStateDashboard((prev) => ({
        ...prev,
        hoverFieldNumber: fieldIdex,
        hoverPolygonNumber: polygonIndex,
        hoverFarmerNumber: farmerIndex,
        selectedElement: selected
      }));
      setBoundsTable(selectedElement, farmerIndex);
    }
  };

  const MouseRowOut = () => {
    if (!props.showPerformanceForm && props._isMounted) {
      props.setStateDashboard((prev) => ({
        ...prev,
        hoverFieldNumber: -1,
        hoverPolygonNumber: -1,
        hoverFarmerNumber: -1,
        selectedElement:null
      }));
      setTimeout(function () {
        if (props.hoverFieldNumber === -1 && props.hoverPolygonNumber === -1) {
          validateMapStatus();
        }
      }, 6000);
    }
  };
  return (
    <Table
      responsive
      hover={false}
      className="detail-table"
      style={{
        paddingBottom: "10px",
        overflow: "auto!important",
        maxHeight: "570px !important",
        height: "570px !important",
      }}
    >
      <thead className="text-primary">
        <tr>
          <th></th>
          <th className="text-center" width={150}></th>
          <th>{t("globals.crop")}</th>
          <th>{t("globals.variety")}</th>
          <th>{t("globals.area")}</th>
          <th>{t("globals.dateSowing")}</th>
          <th>{t("globals.yield")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.fieldList.length &&
          props.fieldList.map((data, dataIndex) => {
            let firtsData = props.fieldList.filter((el) => !el.fields)[0];
            let areaTotal =
              data?.fields?.length > 0 &&
              data.fields?.map((el) => el.area).reduce((a, b) => a + b);
            let farmOptions = selectedFarmOptions.includes(data.id);
            return (
              <>
                {/* FARMS */}
                {data?.fields ? (
                  <>
                    <tr
                      className="field-row"
                      style={{
                        // background: dateActive ? "#5db283" : "#cb5858",
                        background: "#5db283",
                        cursor: "pointer",
                        color: "white",
                      }}
                      key={`field_${dataIndex}`}
                      onMouseOver={() => MouseRowOver(data, -1, -1, dataIndex)}
                      onMouseOut={() => MouseRowOut()}
                      onClick={() => {
                        let repeat = selectedFarmOptions.includes(data.id);
                        if (repeat) {
                          let remove = selectedFarmOptions.filter(
                            (el) => el !== data.id
                          );
                          setSelectedFarmOptions(remove);
                        } else {
                          setSelectedFarmOptions([
                            ...selectedFarmOptions,
                            data.id,
                          ]);
                        }
                      }}
                    >
                      <td>
                        <b
                          className="fa fa-caret-down"
                          style={{ transform: farmOptions && "rotate(180deg)" }}
                        />
                      </td>
                      <td className="text-center">{data.name}</td>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          minWidth: "160px",
                        }}
                      >
                        {areaTotal ? areaTotal?.toFixed(1) : 0 } has
                      </td>
                      <td></td>
                      <td
                        style={{
                          minWidth: "150px",
                        }}
                      ></td>
                      <td></td>
                    </tr>
                    {/* FIELDS IN FARM*/}
                    {data.fields.length > 0
                      ? farmOptions &&
                        data.fields?.map((field, fieldIndex) => {
                          let fieldInFarmOptions =
                            selectedFieldInFarm?.includes(field.id);
                          return (
                            <>
                              <tr
                                className="field-row"
                                key={`field_${fieldIndex}`}
                                onMouseOver={() => {
                                  MouseRowOver(field, fieldIndex, -1, -1);
                                }}
                                onMouseOut={() => MouseRowOut()}
                                onClick={() => {
                                  let repeat = selectedFieldInFarm.includes(
                                    field.id
                                  );
                                  if (repeat) {
                                    let remove = selectedFieldInFarm.filter(
                                      (el) => el !== field.id
                                    );
                                    setSelectedFieldInFarm(remove);
                                  } else {
                                    setSelectedFieldInFarm([
                                      ...selectedFieldInFarm,
                                      field.id,
                                    ]);
                                  }
                                }}
                              >
                                <td>
                                  <p>
                                    <b
                                      className="fa fa-caret-down"
                                      style={{
                                        transform:
                                          fieldInFarmOptions &&
                                          "rotate(180deg)",
                                      }}
                                    />
                                  </p>
                                </td>
                                <td>{field.name}</td>
                                <td></td>
                                <td></td>
                                <td>{field.area?.toFixed(1)} has</td>
                                <td></td>
                                <td
                                  style={{
                                    minWidth: "150px",
                                  }}
                                >
                                  <NavLink
                                    title={t("globals.images")}
                                    className="btn-icon btn-sm btn-neutral"
                                    to={data && `/admin/indexmap/${field.id}`}
                                    style={{ marginRight: "5px" }}
                                  >
                                    <i className="fi flaticon-land" />
                                  </NavLink>
                                  <NavLink
                                    title={t("globals.graphs")}
                                    className="btn-icon btn-sm btn-neutral"
                                    to={
                                      field &&
                                      `/admin/cropmap/detail/${field.id}`
                                    }
                                    style={{ marginRight: "5px" }}
                                  >
                                    <i className="fi flaticon-bar-chart-2" />
                                  </NavLink>
                                  {props.currentUser?.id != null &&
                                    field.owner === props.currentUser.id && (
                                      <a
                                        title={t("globals.share")}
                                        className="btn-icon btn-sm btn-neutral"
                                        onClick={() => {
                                          showShareModal(field);
                                        }}
                                        style={{ color: "#66615B" }}
                                      >
                                        <i className="fi flaticon-leaf-3" />
                                      </a>
                                    )}
                                </td>
                                <td></td>
                              </tr>
                              {/* POLYGONS BY FIELD IN FARM */}
                              {field.polygonData && field.polygonData.length > 0
                                ? fieldInFarmOptions &&
                                  field.polygonData.map(
                                    (polygon, polygonIndex) => (
                                      <tr
                                        className="polygon-row"
                                        key={`polygon_${polygonIndex}`}
                                        onMouseOver={() => {
                                          MouseRowOver(
                                            field,
                                            fieldIndex,
                                            polygonIndex,
                                            -1
                                          );
                                        }}
                                        onMouseOut={() => MouseRowOut()}
                                      >
                                        <td></td>
                                        <td>{polygon.name}</td>
                                        <td>{polygon.crop}</td>
                                        <td>{polygon.variety}</td>
                                        <td>{polygon.area}</td>
                                        {polygon.past ? (
                                          <>
                                            <td>
                                              <div className="content-form-sowing">
                                                <p>
                                                  {polygon.date
                                                    ? polygon.date
                                                    : "-"}
                                                </p>
                                                <Button
                                                  color="warning"
                                                  className="btn-icon"
                                                  style={{ marginLeft: "5px" }}
                                                  size="sm"
                                                  title={`${t(
                                                    "globals.form"
                                                  )} ${t("globals.to")} ${t(
                                                    "globals.sowing"
                                                  )}`}
                                                  type="button"
                                                  onClick={() => {
                                                    props.setStateDashboard(
                                                      (prev) => ({
                                                        ...prev,
                                                        showSeedFormData: true,
                                                        polygonId: polygon.id,
                                                        fieldId: field.id,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <i className="fa fa-file" />
                                                </Button>
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          <td>
                                            {polygon.date ? polygon.date : "-"}
                                          </td>
                                        )}

                                        {polygon.performance ? (
                                          <td>
                                            {polygon.performance + " (Kg/ha)"}
                                          </td>
                                        ) : (
                                          <td className="text-center">
                                            <Button
                                              className="btn-icon"
                                              color="success"
                                              size="sm"
                                              title={`${t("globals.form")} ${t(
                                                "globals.yield"
                                              )}`}
                                              type="button"
                                              disabled={
                                                polygon.yieldDisabled
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                props.setStateDashboard(
                                                  (prev) => ({
                                                    ...prev,
                                                    showPerformanceForm: true,
                                                    polygonId: polygon.id,
                                                    fieldId: field.id,
                                                  })
                                                );
                                              }}
                                            >
                                              <i className="fa fa-edit" />
                                            </Button>
                                          </td>
                                        )}
                                        <td></td>
                                      </tr>
                                    )
                                  )
                                : fieldInFarmOptions && (
                                    <tr>
                                      <td colSpan="2">
                                        {t("noPolygonsInField")}
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          title={t("globals.deleteField")}
                                          type="button"
                                          id="eliminarChacraTooltip"
                                          onClick={() => {
                                            props.setStateDashboard((prev) => ({
                                              ...prev,
                                              deletingFarmModal: false,
                                              showDeleteModal: true,
                                              deleteUrl: field.id,
                                            }));
                                          }}
                                        >
                                          <i className="fa fa-trash-o" />
                                        </Button>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="eliminarChacraTooltip"
                                        >
                                          {t("buttons.deleteFieldButton")}
                                        </UncontrolledTooltip>
                                      </td>
                                    </tr>
                                  )}
                            </>
                            );
                          }) : 
                          farmOptions && (
                          <tr>
                            <td colSpan="2">{t("globals.farmWithoutFields")}</td>
                            <td className="text-center">
                              <Button
                                className="btn-icon"
                                color="danger"
                                size="sm"
                                title={t("gloabls.deleteFarm")}
                                type="button"
                                id="eliminarFarmerTooltip"
                                onClick={() => {
                                  props.setStateDashboard((prev) => ({
                                    ...prev,
                                    deleteFarmModal: true,
                                    showDeleteModal: true,
                                    deleteUrl: data.id,
                                  }));
                                }}
                              >
                                <i className="fa fa-trash-o" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                target="eliminarFarmerTooltip"
                              >
                                {t("globals.deleteFarm")}
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        )}
                  </>
                ) : (
                  <>
                    {firtsData.id === data.id && props.listFarms.length > 0 && (
                      <tr
                        className="field-row"
                        style={{
                          background: "#8b8b8b",
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={() => {
                          setOptionsOtherFields(!optionsOtherFields);
                        }}
                      >
                        <td>
                          <p>
                            <b
                              className="fa fa-caret-down"
                              style={{
                                transform:
                                  optionsOtherFields && "rotate(180deg)",
                              }}
                            />
                          </p>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-center">{t("globals.otherFields")}</td>
                        <td></td>
                        <td
                          style={{
                            minWidth: "150px",
                          }}
                        ></td>
                        <td></td>
                      </tr>
                    )}
                    {optionsOtherFields && (
                      <>
                        <tr
                          className="field-row"
                          key={`field_${dataIndex}`}
                          onMouseOver={() => MouseRowOver(data, dataIndex)}
                          onMouseOut={() => MouseRowOut()}
                          onClick={() => {
                            let repeat = selectedFieldNotFarm.includes(data.id);
                            if (repeat) {
                              let remove = selectedFieldNotFarm.filter(
                                (el) => el !== data.id
                              );
                              setSelectedFieldNotFarm(remove);
                            } else {
                              setSelectedFieldNotFarm([
                                ...selectedFieldNotFarm,
                                data.id,
                              ]);
                            }
                          }}
                        >
                          <td>
                            <p>
                              <b
                                className="fa fa-caret-down"
                                style={{
                                  transform:
                                    selectedFieldNotFarm.includes(data.id) &&
                                    "rotate(180deg)",
                                }}
                              />
                            </p>
                          </td>
                          <td className="text-center">{data.name}</td>
                          <td></td>
                          <td></td>
                          <td
                            style={{
                              minWidth: "160px",
                            }}
                          >
                            {data.area?.toFixed(1)} has
                          </td>
                          <td></td>
                          <td
                            style={{
                              minWidth: "150px",
                            }}
                          >
                            <NavLink
                              title={t("globals.images")}
                              className="btn-icon btn-sm btn-neutral"
                              to={data && `/admin/indexmap/${data.id}`}
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fi flaticon-land" />
                            </NavLink>
                            <NavLink
                              title={t("globals.graphs")}
                              className="btn-icon btn-sm btn-neutral"
                              to={data && `/admin/cropmap/detail/${data.id}`}
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fi flaticon-bar-chart-2" />
                            </NavLink>
                            {props.currentUser?.id != null &&
                              data.owner === props.currentUser.id ? (
                                <a
                                  title={t("globals.share")}
                                  className="btn-icon btn-sm btn-neutral"
                                  onClick={() => {
                                    showShareModal(data);
                                  }}
                                  style={{ color: "#66615B" }}
                                >
                                  <i className="fi flaticon-leaf-3" />
                                </a>
                              ) : (
                                <a
                                  title={t("buttons.stopHavingAccess")}
                                  className="btn-icon btn-sm btn-neutral"
                                  onClick={() => {
                                    showStopShareModal(data);
                                  }}
                                  style={{ color: "#66615B" }}
                                >
                                  <i className="fi flaticon-leaf-3" style={{color:"#e12222"}}/>
                                </a>
                              )}
                          </td>
                          <td></td>
                        </tr>
                        {data.polygonData && data.polygonData.length > 0 ? (
                          data.polygonData.map((polygon, polygonIndex) => {
                            return (
                              selectedFieldNotFarm.includes(data.id) && (
                                <tr
                                  className="polygon-row"
                                  key={`polygon_${polygonIndex}`}
                                  onMouseOver={() =>
                                    MouseRowOver(data, dataIndex, polygonIndex)
                                  }
                                  onMouseOut={() => MouseRowOut()}
                                >
                                  <td></td>
                                  <td>{polygon.name}</td>
                                  <td>{polygon.crop}</td>
                                  <td>{polygon.variety}</td>
                                  <td>{polygon.area}</td>
                                  {polygon.past ? (
                                    <>
                                      <td>
                                        <div className="content-form-sowing">
                                          <p>
                                            {polygon.date ? polygon.date : "-"}
                                          </p>
                                          <Button
                                            color="warning"
                                            className="btn-icon"
                                            style={{ marginLeft: "5px" }}
                                            size="sm"
                                            title={`${t("globals.form")} ${t(
                                              "globals.to"
                                            )} ${t("globals.sowing")}`}
                                            type="button"
                                            onClick={() => {
                                              props.setStateDashboard(
                                                (prev) => ({
                                                  ...prev,
                                                  showSeedFormData: true,
                                                  polygonId: polygon.id,
                                                  fieldId: data.id,
                                                })
                                              );
                                            }}
                                          >
                                            <i className="fa fa-file" />
                                          </Button>
                                        </div>
                                      </td>
                                    </>
                                  ) : (
                                    <td>{polygon.date ? polygon.date : "-"}</td>
                                  )}

                                  {polygon.performance ? (
                                    <td>{polygon.performance + " (Kg/ha)"}</td>
                                  ) : (
                                    <td className="text-center">
                                      <Button
                                        className="btn-icon"
                                        color="success"
                                        size="sm"
                                        title={`${t("globals.form")} ${t(
                                          "globals.yield"
                                        )}`}
                                        type="button"
                                        disabled={
                                          polygon.yieldDisabled ? true : false
                                        }
                                        onClick={() => {
                                          props.setStateDashboard((prev) => ({
                                            ...prev,
                                            showPerformanceForm: true,
                                            polygonId: polygon.id,
                                            fieldId: data.id,
                                          }));
                                        }}
                                      >
                                        <i className="fa fa-edit" />
                                      </Button>
                                    </td>
                                  )}
                                  <td></td>
                                </tr>
                              )
                            );
                          })
                        ) : (
                          selectedFieldNotFarm.includes(data.id) &&
                          <tr>
                            <td colSpan="2">{t("noPolygonsInField")}</td>
                            <td className="text-center">
                              <Button
                                className="btn-icon"
                                color="danger"
                                size="sm"
                                title={t("globals.deleteField")}
                                type="button"
                                id="eliminarChacraTooltip"
                                onClick={() => {
                                  props.setStateDashboard((prev) => ({
                                    ...prev,
                                    deletingFarmModal: false,
                                    showDeleteModal: true,
                                    deleteUrl: data.id,
                                  }));
                                }}
                              >
                                <i className="fa fa-trash-o" />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                target="eliminarChacraTooltip"
                              >
                                {t("buttons.deleteFieldButton")}
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
          })}
      </tbody>
    </Table>
  );
};

export default TableDashboard;
