import React, { Component, useEffect, useState, useContext } from "react";
import "leaflet/dist/leaflet.css";
import "../../../assets/css/map.css";
import * as moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import DetailMap from "./components/detail-map";
import Modal from "reactstrap/es/Modal";
import Select from "react-select";
import ReactDatetime from "react-datetime";

import IndexChart from "./components/index-chart";
import TermicWidget from "./components/termicwidget";

import MapPolygonEdit from "./components/map-polygon-edit";
import Spinner from "reactstrap/es/Spinner";

import Dropzone from "./components/Dropzone.js";
import axios from "axios";
import ReutMapDetail from "./settingSteps/reutilizarMapDetail/reutilizarAddField/ReutMapDetail";
import SourceProvider from "providers/SourceProvider";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import ErrorCreateFieldModal from "./components/ErrorCreateFieldModal";

const mapDetail = (props) => {
  const [t, i18n] = useTranslation("global")
  const providerPlanSource = useContext(SourceProvider);
  const headers = {
    headers: {
      // ACA VOY A TRAER EL TOKEN DE LOCALSTORAGE
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };
  const [newPolygonsId, setNewPolygonsId] = useState([]);
  const [namePolygon, setNamePolygon] = useState("");
  const [confirmDeleteField, setConfirmDeleteField] = useState("");
  const [updateNamePolygon, setUpdateNamePolygon] = useState("");
  const [geoJsonFeatures, setGeoJsonFeatures] = useState([]);
  const [state, setState] = useState({
    idPolygons: [],
    addPolStatus: false,
    centerLocation: { lat: -32, lng: -57 },
    zoom: 14,
    fieldInfo: {},
    polygons: [],
    fieldsUrl: "",
    currentUser: localStorage.getItem("token"),
    userInfo: "",
    bounds: [],
    deleteStatus: false,
    polyIndex: 0,
    zafras: [],

    showDeleteModal: false,
    showUpdateFieldNameModal: false,
    selectIndex: "",
    selectStatus: false,
    hoverNumber: -1,
    cropArr: [],
    cropArrAux: [],
    varietyArr: [],
    varietyArrAux: [],
    fieldId: [],
    loadingTable: false,
    index_data: [],

    chatInfo: null,

    showPolygonDeleteModal: false,
    selectedDeletePolygonId: "",
    deletePolygonStatus: false,

    // update part
    selectedPolygon: null,
    changedCoords: [],
    initGeoJson: {
      type: "FeatureCollection",
      features: [],
    },
    showUpdateModal: false,
    selectedPolygonId: "",

    // add part
    createdPolygon: null,
    createdCoords: [],
    showAddModal: false,
    showAddConfirmModal: false,

    //reut part
    showReutModal: false,
    selectPolygon: [],
    selectedPolygonsArr: [],
    statusPolygon: false,
    logSowingPolygonsDeleted: [],
    //common of add and update
    otherPolygonsArr: [],
    //form
    crop: null,
    variety: null,
    predecesor: null,
    sowing_date: null,
    fieldList: [],

    predecesorState: true,

    predecessorArray: [],

    predecesorVariety: null,
    predecesorVarietyArr: [],

    isLoading: false,

    showAllPoligonsYieldWarning: false,

    showKmlModal: false,
    kmlPolygonData: [],
    polygonsArr: [],
    fromKml: false,
    createdWithSowing: true,
    userOwner: true,
    mapShow: false,
    validCropsAndVarieties: false,
    // zeroDate: "20/10/2022",

    //disabledButtons
    disabledKmlButton: false,
    disabledSavedButton: false,

    // error de superposicion
    errorSuperposition: false
  });

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let fieldId = params.id;
    setState((prev) => ({
      ...prev,
      fieldId: [fieldId],
      isLoading: true,
    }));

    if (!state.currentUser) {
      props.history.push("/auth/login");
    } else {
      axios
        .get(`https://agrodigital.io/api/auth/user/`, headers)
        .then((infoUser) => {
          setState((prev) => ({
            ...prev,
            userInfo: infoUser.data,
            currentUser: localStorage.getItem("token"),
          }));
          fetchCropsAndVarieties(infoUser.data);
        });

    }
  }, []);

  useEffect(() => {
    if (
      state.fieldList.length === 0 &&
      state.fieldId.length > 0 &&
      state.userInfo &&
      state.validCropsAndVarieties
    ) {
      fetchField();
    }
    if (state.fieldList.length) {
      setBounds(state.fieldList);
    }
  }, [
    state.fieldList.length,
    state.fieldId.length,
    state.userInfo,
    state.validCropsAndVarieties,
  ]);

  useEffect(() => {
    if (state.polygons.length > 0) {
      yearChange("ACTUAL");
    }
  }, [state.polygons.length]);

  useEffect(() => {
    if (newPolygonsId.length) {
      addPolygonField(newPolygonsId);
    }
  }, [newPolygonsId.length]);

  //cuando se importan los KML
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      mapShow: true,
    }));
  }, [state.otherPolygonsArr.length]);

  const onUpdateModalToggle = () => {
    setState((prev) => ({
      ...prev,
      showUpdateModal: !state.showUpdateModal,
    }));
  };

  const onAddModalToggle = () => {
    setState((prev) => ({
      ...prev,
      showAddModal: !state.showAddModal,
      createdCoords: [],
      createdWithSowing: true,
      showAddConfirmModal: false,
      crop: null,
      variety: null,
      predecesor: null,
      sowing_date: null,
      fromKml: false,
      otherPolygonsArr: [],
      disabledKmlButton: false,
      disabledSavedButton: false,
    }));
  };
  const onReutilizarModalToggle = () => {
    setState((prev) => ({
      ...prev,
      showReutModal: !state.showReutModal,
      selectedPolygonsArr: [],
    }));
  };

  const onDeleteToggle = () => {
    setState((prev) => ({
      ...prev,
      showDeleteModal: !state.showDeleteModal,
    }));
  };

  const onDeletePolygonToggle = () => {
    setState((prev) => ({
      ...prev,
      showPolygonDeleteModal: !state.showPolygonDeleteModal,
    }));
  };

  const onDeleteField = () => {
    onDeleteToggle();
    setState((prev) => ({
      ...prev,
      deleteStatus: true,
    }));

    axios
      .delete(`https://agrodigital.io/api/fields/${state.fieldId}/`, headers)
      .then(function () {
        setState((prev) => ({
          ...prev,
          deleteStatus: false,
        }));

        props.history.push("/admin/cropmap/map");
      })
      .catch(function (error) {
        console.error("Error writing to field_delete: ", error);
        setState((prev) => ({
          ...prev,
          deleteStatus: false,
        }));
      });
  };

  const setInitGeoJson = (selectedPolygon) => {
    let result = {
      type: "FeatureCollection",
      features: [],
    };

    let realCoords = [];

    if (selectedPolygon) {
      let coords = selectedPolygon.boundary.coordinates[0];
      if (coords) {
        for (let i = 0; i < coords.length; i++) {
          let tempCoord = coords[i];

          let tempArr = [];
          tempArr[0] = tempCoord[0];
          tempArr[1] = tempCoord[1];

          realCoords.push(tempArr);
        }

        if (realCoords) {
          result = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Polygon",
                  coordinates: [realCoords],
                },
              },
            ],
          };
        }
      }
    }
    setState((prev) => ({
      ...prev,
      changedCoords: realCoords,
      initGeoJson: result,
    }));
  };
  const setOtherPolygonsArr = (selectedPolygonId = "", bAdd = true) => {
    let currentPolygons = state.polygons;

    let resultArr = [];
    if (currentPolygons.length > 0) {
      currentPolygons.map((subItem, subIndex) => {
        if (bAdd === true) {
          let tempCoords = subItem.boundary.coordinates[0];
          let coords = [];
          let name = subItem.name;

          tempCoords.map((coord) => {
            let tempArr = [];
            tempArr[0] = coord[0];
            tempArr[1] = coord[1];

            coords.push(tempArr);
          });
          let tempPolygonInfo = {
            name: "",
            coordsArr: [],
          };
          tempPolygonInfo.name = name;
          tempPolygonInfo.coordsArr[0] = coords;

          resultArr.push(tempPolygonInfo);
        } else {
          if (subItem.id !== selectedPolygonId) {
            let tempCoords = subItem.boundary.coordinates[0];
            let coords = [];
            let name = subItem.name;

            tempCoords.map((coord) => {
              let tempArr = [];
              tempArr[0] = coord[0];
              tempArr[1] = coord[1];

              coords.push(tempArr);
            });

            let tempPolygonInfo = {
              name: "",
              coordsArr: [],
            };
            tempPolygonInfo.name = name;
            tempPolygonInfo.coordsArr[0] = coords;

            resultArr.push(tempPolygonInfo);
          }
        }
      });
    }
    setState((prev) => ({
      ...prev,
      otherPolygonsArr: resultArr,
    }));
  };

  const fetchField = async () => {
    let fieldId = Number(state.fieldId[0]);

    let fieldReference = await axios.get(
      `https://agrodigital.io/api/fields/${fieldId}`,
      headers
    );

    if (
      fieldReference.data.deleted === null &&
      fieldReference.data.id === fieldId
    ) {
      let checkUserOwner = fieldReference.data?.owner === state.userInfo.id;
      setState((prev) => ({
        ...prev,
        fieldInfo: fieldReference.data,
        userOwner: checkUserOwner,
      }));
      fetchPolygons(fieldReference.data);
    } else {
      setState((prev) => ({
        ...prev,
        fieldList: [],
        isLoading: false,
      }));
    }
  };

  const fetchPolygons = (fieldReference) => {
    let fieldId = Number(fieldReference.id);

    if (fieldId) {
      let seedLogReferenceSowing = axios.get(
        `https://agrodigital.io/api/logs/?field=${fieldId}&type=Sowing`,
        headers
      );
      let polygonData = axios.get(
        `https://agrodigital.io/api/polygons/?field=${fieldId}`,
        headers
      );
      Promise.all([seedLogReferenceSowing, polygonData]).then((res) => {
        let itemPolygonResult = [];
        let registroSiembra = res[0].data;
        let poligonos = res[1].data;
        poligonos.forEach((itemPolygon) => {
          let sowing = registroSiembra
            .filter((registro) => registro.polygons.includes(itemPolygon.id))
            .sort(
              (a, b) =>
                new Date(a.date + "T00:00:00").getTime() -
                new Date(b.date + "T00:00:00").getTime()
            )
            .pop();

          if (sowing) {
            itemPolygon.date = sowing.date;
            itemPolygon.dateTable = moment(
              new Date(sowing.date + "T00:00:00")
            ).format("DD-MM-YYYY");
            if (sowing.variety) {
              itemPolygon.crop = state.cropArr.filter(
                (el) => el.value === sowing.crop
              )[0]?.specie;
              itemPolygon.cropName = state.cropArr.filter(
                (el) => el.value === sowing.crop
              )[0]?.label;
              itemPolygon.variety = state.varietyArr.filter(
                (el) => el.value === sowing.variety
              )[0]?.label;
            } else {
              itemPolygon.crop = state.cropArr.filter(
                (el) => el.value === sowing.crop
              )[0]?.specie;
              itemPolygon.cropName = state.cropArr.filter(
                (el) => el.value === sowing.crop
              )[0]?.label;
              itemPolygon.variety = "-";
            }
            if (sowing.predecessor) {
              let changePredecessor = state.cropArr.filter(
                (el) => el.value === sowing.predecessor
              )[0];
              itemPolygon.predecessor = changePredecessor?.specie;
            }
          } else {
            itemPolygon.crop = "-";
            itemPolygon.variety = "-";
            itemPolygon.date = "-";
          }
          itemPolygonResult.push(itemPolygon);
          fieldReference.polygonData = itemPolygonResult;
        });

        //ordenar primero los que si tienen siembra despues los que no tienen
        let polygonSowing = poligonos
          .filter((el) => el.date !== "-")
          .sort(
            (a, b) =>
              new Date(a.date + "T00:00:00").getTime() -
              new Date(b.date + "T00:00:00").getTime()
          );
        let polygonNoSowing = poligonos.filter((el) => el.date === "-");
        let orderBySowing = polygonSowing.concat(polygonNoSowing);

        setState((prev) => ({
          ...prev,
          polygons: orderBySowing,
          fieldList: [fieldReference],
          isLoading: false,
          mapShow: true,
        }));
      });
    }
  };

  const fetchCropsAndVarieties = async (infoUser) => {
    let crops = await axios.get(`https://agrodigital.io/api/crops/`, headers);
    let varieties = await axios.get(
      `https://agrodigital.io/api/varieties/`,
      headers
    );

    if (varieties.status === 200 && crops.status === 200) {
      let filterCropByCountry = crops.data.filter((el)=> el.region.includes(infoUser.country))
      let filterVarietyByCountry = varieties.data.filter((el)=> el.region.includes(infoUser.country))
      let dataInfoCrops = filterCropByCountry.sort((a, b) =>
      a.name.localeCompare(b.name)
    ).map((el) => ({
        value: el.id,
        label: el.name,
        specie: el.specie,
      }));
      let dataInfoVarieties = filterVarietyByCountry.sort((a, b) =>
      a.name.localeCompare(b.name)
    ).map((el) => ({
        value: el.id,
        label: el.name,
        crop: el.crop,
      }));

      if (dataInfoCrops.length > 0 && dataInfoVarieties.length > 0) {
        setState((prev) => ({
          ...prev,
          cropArr: dataInfoCrops,
          predecessorArray: dataInfoCrops,
          varietyArr: dataInfoVarieties,
          validCropsAndVarieties: true,
        }));
      }
    }
  };

  const filterVarieties = (value, predecesor) => {
    let dataInfo = state.varietyArr
      .map((item) => {
        if (value?.value === item.crop) {
          return {
            value: item.value,
            label: item.label,
          };
        }
      })
      .filter((el) => el !== undefined);
    if (predecesor) {
      setState((prev) => ({
        ...prev,
        predecesorVarietyArr: dataInfo,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        varietyArrAux: dataInfo,
      }));
    }
  };
  const processCreatedInfo = async () => {
    let noSowing = true;
    if (!state.fromKml) {
      if (!state.createdCoords) {
        toast.error(t("alerts.drawPolygon"));
        return;
      }
    }
    if (state.crop || state.sowing_date) {
      if (!state.crop) {
        toast.error(t("alerts.selectCrop"));
        return;
      }

      if (!state.sowing_date) {
        toast.error(t("alerts.selectSowingDate"));
        return;
      }

      noSowing = false;
    } else {
      noSowing = true;
    }

    if (state.fromKml) {
      for (let i = 0; i < state.polygonsArr.length; i++) {
        let kmlPolygon = state.polygonsArr[i];

        let coords = kmlPolygon.coordsArr[0];
        let realCoords = [];
        for (let j = 0; j < coords.length; j++) {
          let tempVal = coords[j];
          let tempStructure = [tempVal[0], tempVal[1]];
          realCoords.push(tempStructure);
        }
        let tempSowingDate = state.sowing_date;

        let tempDate = "";

        if (typeof tempSowingDate === "string") {
          tempDate = new Date(tempSowingDate);
        } else {
          tempDate = tempSowingDate?.toDate();
        }

        let polygonData = {
          name: kmlPolygon.name,
          boundary: {
            type: "Polygon",
            coordinates: [realCoords],
          },
          deleted: null,
          date: moment(new Date(tempDate)).format("YYYY-MM-DD"),
          owner: state.userInfo.id,
        };
        addPolygon(polygonData, noSowing);
      }
    } else {
      if (state.sowing_date) {
        if (!namePolygon) {
          toast.error(t("alerts.insertPolygonName"));
          return;
        }
        let tempSowingDate = state.sowing_date;

        let tempDate = "";

        if (typeof tempSowingDate === "string") {
          tempDate = new Date(tempSowingDate);
        } else {
          tempDate = tempSowingDate?.toDate();
        }

        let polygonData = {
          tempId: geoJsonFeatures[geoJsonFeatures.length - 1]?.tempId,
          name: namePolygon,
          boundary: {
            type: "Polygon",
            coordinates: geoJsonFeatures[geoJsonFeatures.length - 1]?.coords,
          },
          deleted: false,
          date: moment(new Date(tempDate)).format("YYYY-MM-DD"),
          owner: state.userInfo.id,
        };

        let removing = geoJsonFeatures.filter(
          (el) => el.tempId !== polygonData.tempId
        );
        setGeoJsonFeatures(removing);

        addPolygon(polygonData, noSowing);
      } else {
        let result = [];
        for (let i = 0; i < geoJsonFeatures.length; i++) {
          const features = geoJsonFeatures[i];

          let polygonData = {
            tempId: i,
            name: !namePolygon ? "SinNombre" + i : namePolygon,
            boundary: {
              type: "Polygon",
              coordinates: features?.coords,
            },
            deleted: false,
            // date: "",
            owner: state.userInfo.id,
          };
          if (features.tempId !== polygonData.tempId) {
            result.push(features);
          }
          addPolygon(polygonData, noSowing);
        }
        setGeoJsonFeatures(result);
      }
    }
  };

  const addPolygon = async (polygonData, noSowing) => {
    // Crear registro y traerme el id de los poligonos creados
    await axios
      .post(`https://agrodigital.io/api/polygons/`, polygonData, headers)
      .then(async (createPolygon) => {
        let polygon = createPolygon.data;
        if (!noSowing) {
          let insertData = {};
          insertData.date = polygonData.date;
          insertData.obs = "";
          (insertData.crop = state.crop.value ? state.crop?.value : "-"),
            (insertData.variety = state.variety ? state.variety?.value : null);
          (insertData.predecessor = state.predecesor?.value
            ? state.predecesor.value
            : null),
            (insertData.polygons = [polygon.id]);
          insertData.field = Number(state.fieldId)
          insertData.type = "Sowing";
          await axios.post(
            "https://agrodigital.io/api/logs/",
            insertData,
            headers
          );
          setNewPolygonsId((prev) => [...prev, polygon.id]);
        } else {
          setNewPolygonsId((prev) => [...prev, polygon.id]);
        }
      })
      .catch((error)=>{
        const request = Object.values(error);
        const message = request[2];
        if(message?.data[0]){
          let errorMessage = message.data[0]
          if(errorMessage === "The new polygon intersects too much with another one"){
            setState((prev) => ({
              ...prev,
              errorSuperposition: true,
            }));
            
          }
        }
      });
  };
  const addPolygonField = (polygonsId) => {
    axios
      .get(
        `https://agrodigital.io/api/polygons/?field=${state.fieldId[0]}`,
        headers
      )
      .then((res) => {
        const prevPolygonId = res.data;
        const polygons = [];
        //si hay polygons asociados edita el field y agrega los que creaste recien con los que ya estaban
        if (prevPolygonId.length > 0) {
          prevPolygonId.forEach((element) => {
            polygons.push(element.id);
          });
          polygons.push(...polygonsId);
          addPolygonsCompleted(polygons);
        } else {
          addPolygonsCompleted(polygonsId);
        }
      });
  };
  const addPolygonsCompleted = (polygonsId) => {
    axios
      .patch(
        `https://agrodigital.io/api/fields/${state.fieldId[0]}/`,
        { polygons: polygonsId },
        headers
      )
      .then((res) => {
        setState((prev) => ({
          ...prev,
          crop: null,
          variety: null,
          predecesor: null,
          sowing_date: null,
          addPolStatus: true,
          loadingTable: true,
          showAddConfirmModal: false,
          fieldList: [],
          createdCoords: [],
          createdWithSowing: geoJsonFeatures.length > 0 ? false : true,
          mapShow: geoJsonFeatures.length > 0 ? true : false,
          disabledKmlButton: false,
          disabledSavedButton: false,
          fromKml: false,
        }));
        providerPlanSource.changePlan("restart", false);
        setNamePolygon("");
        setNewPolygonsId([]);
        setTimeout(() => {
          setState((prev) => ({
            ...prev,
            mapShow: true,
          }));
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const removeDuplicates = (array) => {
    return array.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.id === obj.id && o.name === obj.name)
    );
  };

  const getSelectedPolygons = (polygonSelected, status) => {
    if (status === "add") {
      if (polygonSelected.delete === null) {
        setState((prev) => ({
          ...prev,
          selectedPolygonsArr: removeDuplicates([
            ...prev.selectedPolygonsArr,
            polygonSelected,
          ]),
          showAddConfirmModal: true,
          statusPolygon: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          selectedPolygonsArr: [...prev.selectedPolygonsArr, polygonSelected],
          showAddConfirmModal: true,
          statusPolygon: true,
        }));
      }
    }
    if (status === "delete") {
      const newState = (prev) => {
        return prev.filter((el) => el.id != polygonSelected);
      };
      setState((prev) => ({
        ...prev,
        selectedPolygonsArr: newState(prev.selectedPolygonsArr),
      }));
    }
  };

  const createdLogsPolygonsDelete = async () => {
    let logsList = state.logSowingPolygonsDeleted;
    let acum = 0;
    for (let i = 0; i < logsList.length; i++) {
      await axios
        .post("https://agrodigital.io/api/logs/", logsList[i], headers)
        .then(() => {
          acum += 1;
          if (acum === logsList.length) {
            setState((prev) => ({
              ...prev,
              showReutModal: false,
              loadingTable: true,
              fieldList: [],
            }));
          }
        });
    }
  };

  const onSaveSelectPolygons = async (polygonsSelected) => {
    let idPolygons = polygonsSelected.map((el) => el.id);
    await axios
      .get(
        `https://agrodigital.io/api/polygons/?field=${state.fieldId}`,
        headers
      )
      .then(async (res) => {
        const prevPolygonId = res.data;
        //si hay polygons asociados edita el field y agrega los que creaste recien con los que ya estaban
        if (prevPolygonId.length > 0) {
          let prevIdPolygon = prevPolygonId.map((el) => el.id);
          let union = idPolygons.concat(prevIdPolygon);
          await axios
            .patch(
              `https://agrodigital.io/api/fields/${state.fieldId}/`,
              { polygons: union },
              headers
            )
            .then((res) => {
              if (state.logSowingPolygonsDeleted.length > 0) {
                createdLogsPolygonsDelete();
              } else {
                setState((prev) => ({
                  ...prev,
                  showReutModal: false,
                  loadingTable: true,
                  fieldList: [],
                }));
                providerPlanSource.changePlan("restart", false)
              }
            })
            .catch((err) => console.log(err));
        } else {
          await axios
            .patch(
              `https://agrodigital.io/api/fields/${state.fieldId}/`,
              { polygons: idPolygons },
              headers
            )
            .then((res) => {
              if (state.logSowingPolygonsDeleted.length > 0) {
                createdLogsPolygonsDelete();
              } else {
                setState((prev) => ({
                  ...prev,
                  showReutModal: false,
                  loadingTable: true,
                  fieldList: [],
                }));
                providerPlanSource.changePlan("restart", false)
              }
            })
            .catch((err) => console.log(err));
        }
      });
  };

  const createRegisterPolygonAsign = () => {
    let tempSowingDate = state.sowing_date;
    let polygonSelect =
      state.selectedPolygonsArr[state.selectedPolygonsArr.length - 1];
    let tempDate = "";
    if (typeof tempSowingDate === "string") {
      tempDate = new Date(tempSowingDate);
    } else {
      tempDate = tempSowingDate.toDate();
    }
    let date = moment(new Date(tempDate)).format("YYYY-MM-DD");

    let insertData = {};

    insertData.date = date;
    insertData.crop = state.crop ? state.crop.value : "-";
    insertData.variety = state.variety ? state.variety.value : null;
    insertData.predecessor = state.predecesor ? state.predecesor.value : null;
    insertData.polygons = [polygonSelect.id];
    insertData.field = Number(state.fieldId);
    insertData.type = "Sowing";

    if (state.statusPolygon) {
      setState((prev) => ({
        ...prev,
        logSowingPolygonsDeleted: [
          ...prev.logSowingPolygonsDeleted,
          insertData,
        ],
        showAddConfirmModal: false,
        crop: null,
        variety: null,
        predecesor: null,
        sowing_date: null,
      }));
    } else {
      axios
        .post("https://agrodigital.io/api/logs/", insertData, headers)
        .then((res) => {
          setState((prev) => ({
            ...prev,
            showAddConfirmModal: false,
            crop: null,
            variety: null,
            predecesor: null,
            sowing_date: null,
          }));
        });
    }
  };
  //TOAST
  const processUpdatedInfo = async () => {
    if (!state.changedCoords) {
      toast.error(t("alerts.drawPolygon"));
      return;
    }

    if (!state.selectedPolygonId) {
      return;
    }

    let coords = state.changedCoords;
    let realCoords = [];
    for (let j = 0; j < coords.length; j++) {
      let tempVal = coords[j];

      let tempStructure = [tempVal[0], tempVal[1]];
      realCoords.push(tempStructure);
    }
    let selectedPolygonId = state.selectedPolygonId;

    let tempSowingDate = state.sowing_date;

    let tempDate = "";

    if (typeof tempSowingDate === "string") {
      tempDate = tempSowingDate;
    } else {
      tempDate = tempSowingDate;
    }
    setState((prev) => ({
      ...prev,
      loadingTable: true,
    }));
    let polygonData = {
      name: namePolygon,
      boundary: {
        type: "Polygon",
        coordinates: [realCoords],
      },
      deleted: false,
    };
    axios
      .patch(
        `https://agrodigital.io/api/polygons/${selectedPolygonId}/?field=${state.fieldId}/`,
        polygonData,
        headers
      )
      .then(() => {
        setState((prev) => ({
          ...prev,
          loadingTable: true,
          fieldList: [],
        }));
      })
      .catch(function (error) {
        setState((prev) => ({
          ...prev,
          loadingTable: false,
        }));
      });

    onUpdateModalToggle();
  };

  const getAddMapBounds = () => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;

    let polygons = state.polygons;
    let centerPosition = state.centerLocation;
    let result = [
      [centerPosition.lat, centerPosition.lng],
      [centerPosition.lat, centerPosition.lng],
    ];

    if (polygons.length > 0) {
      for (let j = 0; j < polygons.length; j++) {
        let coordinates = polygons[j].boundary.coordinates[0];
        for (let k = 0; k < coordinates.length; k++) {
          let coords = coordinates[k];
          let latitude = coords[1];
          let longitude = coords[0];
          if (latitude > top) {
            top = coords[1];
          }

          if (latitude < bottom) {
            bottom = coords[1];
          }

          if (longitude > left) {
            left = longitude;
          }

          if (longitude < right) {
            right = longitude;
          }
        }
      }

      result = [
        [top, left],
        [bottom, right],
      ];
    }
    return result;
  };

  const getUpdateMapBounds = () => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;

    let polygon = state.selectedPolygon;
    let result = [];

    if (polygon) {
      let coordinates = polygon.boundary.coordinates[0];
      for (let k = 0; k < coordinates.length; k++) {
        let coords = coordinates[k];
        let latitude = coords[1];
        let longitude = coords[0];
        if (latitude > top) {
          top = coords[1];
        }

        if (latitude < bottom) {
          bottom = coords[1];
        }

        if (longitude > left) {
          left = longitude;
        }

        if (longitude < right) {
          right = longitude;
        }
      }

      result = [
        [top, left],
        [bottom, right],
      ];
    }

    return result;
  };

  const changeCrop = (value, predecesor) => {
    if (predecesor) {
      if (value.label === "Arroz") {
        filterVarieties(value, predecesor);
        setState((prev) => ({
          ...prev,
          predecesor: value,
          predecesorState: true,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          predecesor: value,
          predecesorState: true,
          predecesorVarietyArr: [],
          predecesorVariety: null,
        }));
      }
    } else {
      filterVarieties(value, predecesor);
      setState((prev) => ({
        ...prev,
        crop: value,
        cropState: true,
      }));
    }
  };

  const MouseHover = (index) => {
    setState((prev) => ({
      ...prev,
      hoverNumber: index,
    }));
  };

  const yearChange = (index, date) => {
    if (
      index === "ACTUAL" &&
      state.polyIndex in state.polygons &&
      state.polygons.length > 0
    ) {
      if (date !== undefined) {
        setState((prev) => ({
          ...prev,
          zeroDate: moment(new Date(date + "T00:00:00")).format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        }));
      } else {
        // let sort = [...state.polygons].sort(
        //   (a, b) =>
        //     new Date(a.date + "T00:00:00").getTime() -
        //     new Date(b.date + "T00:00:00").getTime()
        // );
        // let dates = sort.shift();
        setState((prev) => ({
          ...prev,
          zeroDate: undefined,
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        }));
      }
    }
  };

  const MouseOut = (index) => {
    setState((prev) => ({
      ...prev,
      hoverNumber: -1,
    }));
  };

  const onDeletePolygonInfo = (polygon) => {
    setState((prev) => ({
      ...prev,
      selectedDeletePolygonId: polygon.id,
    }));
    onDeletePolygonToggle();
  };

  const onDeletePolygon = () => {
    let selectedDeletePolygonId = state.selectedDeletePolygonId;
    let fieldId = state.fieldId;

    if (selectedDeletePolygonId === "" || fieldId.length === 0) {
      onDeletePolygonToggle();
      return;
    }

    setState((prev) => ({
      ...prev,
      deletePolygonStatus: true,
    }));

    const deletePolygon = async () => {
      axios
        .delete(
          `https://agrodigital.io/api/polygons/${selectedDeletePolygonId}`,
          headers
        )
        .then(function () {
          setState((prev) => ({
            ...prev,
            deletePolygonStatus: false,
            showPolygonDeleteModal: false,
            loadingTable: true,
            polyIndex: 0,
            fieldList: [],
          }));
          providerPlanSource.changePlan("restart", false);
        })
        .catch(function (error) {
          console.error("Error deleted polygon: ", error);
          setState((prev) => ({
            ...prev,
            deletePolygonStatus: false,
            showPolygonDeleteModal: false,
          }));
        });
    };

    deletePolygon();
  };

  const onCloseAddConfirmModal = () => {
    setState((prev) => ({
      ...prev,
      createdWithSowing: false,
      createdPolygon: null,
      showAddConfirmModal: false,
      crop: null,
      variety: null,
      predecesor: null,
      sowing_date: null,
    }));
  };

  const onShowAddModal = () => {
    setNamePolygon("");
    setState((prev) => ({
      ...prev,
      crop: null,
      variety: null,
      sowing_date: null,
      varietyArrAux: [],
      createdCoords: [],
      createdPolygon: null,
      showKmlModal: false,
      predecesor: null,
      predecesorVariety: null,
      predecesorVarietyArr: [],
      predecesorState: true,
      kmlPolygonData: [],
      polygonsArr: [],
      fromKml: false,
    }));
    setOtherPolygonsArr("", true);
    onAddModalToggle();
  };
  const onShowReutilizarPol = () => {
    setNamePolygon("");
    setState((prev) => ({
      ...prev,
      showAddModal: false,
      crop: null,
      variety: null,
      sowing_date: null,
      varietyArrAux: [],
      createdCoords: [],
      createdPolygon: null,
      showKmlModal: false,
      predecesor: null,
      predecesorVariety: null,
      predecesorVarietyArr: [],
      predecesorState: true,
      kmlPolygonData: [],
      polygonsArr: [],
      fromKml: false,
    }));
    onReutilizarModalToggle();
  };

  const onCloseYieldAlert = () => {
    setState((prev) => ({
      ...prev,
      showAllPoligonsYieldWarning: false,
    }));
  };

  const onShowAddConfirmModalToggle = () => {
    setState((prev) => ({
      ...prev,
      showAddConfirmModal: !state.showAddConfirmModal,
    }));
  };

  //boton editar polygon
  const onShowUpdateModal = (polygon = null) => {
    let tempPolygonId = polygon.id;
    let tempPolygonName = polygon.name;

    var tempCrop = null;
    if (polygon.crop && state.cropArr.length > 0) {
      tempCrop = state.cropArr
        .filter((crop) => crop.value == polygon.crop)
        .pop();
    }

    let tempVariety = null;
    if (polygon.variety) {
      tempVariety = {
        value: polygon.variety,
        label: polygon.variety,
      };
    }

    let tempSowingDate = null;
    if (polygon.date !== "-" && polygon.date) {
      let date = new Date(polygon.date);
      let month =
        date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      let year = date.getFullYear();

      tempSowingDate = month + "/" + day + "/" + year;
    } else {
      tempSowingDate = "-";
    }

    setNamePolygon(tempPolygonName);
    setState((prev) => ({
      ...prev,
      selectedPolygonId: tempPolygonId,
      selectedPolygon: polygon,
      crop: tempCrop,
      variety: tempVariety,
      sowing_date: tempSowingDate,
    }));
    filterVarieties(tempCrop);
    setOtherPolygonsArr(tempPolygonId, false);
    setInitGeoJson(polygon);
    onUpdateModalToggle();
  };

  //dibujar nuevo polygono icono eliminar lo dibujado
  const onCreatedPolygon = (geoJson) => {
    if (geoJson.features) {
      let tempGeoJson = [];
      let features = geoJson.features;

      // if (features?.type === "Polygon" && features?.coordinates) {
      //   tempGeoJson = features.coordinates[0];
      //   // onShowAddConfirmModalToggle();
      // }
      for (let i = 0; i < features?.length; i++) {
        let jsonGeometry = features[i]?.geometry;
        let tempInfo = {
          tempId: i,
          name: "polygonDraw",
          coords: jsonGeometry.coordinates,
        };

        tempGeoJson.push(tempInfo);
      }

      setGeoJsonFeatures(tempGeoJson);
      onShowAddConfirmModalToggle();
    }
  };

  const onUpdatedPolygon = (geoJson) => {
    if (geoJson.features) {
      let tempGeoJson = [];

      let temp = geoJson.features[0].geometry;

      if (temp.type === "Polygon" && temp.coordinates) {
        tempGeoJson = temp.coordinates[0];
      }
      setState((prev) => ({
        ...prev,
        changedCoords: tempGeoJson,
      }));
    }
  };

  //REALIZAR CUANDO APAREZCAN LOS INDICES
  const setBounds = (fieldList) => {
    let left = -180;
    let top = -90;
    let right = 180;
    let bottom = 90;

    let result = [
      [state.centerLocation.lng, state.centerLocation.lat],
      [state.centerLocation.lng, state.centerLocation.lat],
    ];
    if (fieldList.length > 0) {
      if (fieldList) {
        for (let i = 0; i < fieldList.length; i++) {
          let polygons = fieldList[i].polygonData;
          if (polygons) {
            for (let j = 0; j < polygons.length; j++) {
              let coords = polygons[j].boundary.coordinates;
              if (coords) {
                for (let k = 0; k < coords.length; k++) {
                  let coordinates = coords[k];
                  for (let l = 0; l < coordinates.length; l++) {
                    let latitude = coordinates[l][1];
                    let longitude = coordinates[l][0];
                    if (latitude > top) {
                      top = coordinates[l][1];
                    }

                    if (latitude < bottom) {
                      bottom = coordinates[l][1];
                    }

                    if (longitude > left) {
                      left = longitude;
                    }

                    if (longitude < right) {
                      right = longitude;
                    }
                  }
                }
              }
            }
          }
        }
        result = [
          [top, left],
          [bottom, right],
        ];
      }
    }
    setState((prev) => ({
      ...prev,
      bounds: result,
      loadingTable: false,
      isLoading: false,
    }));
  };

  const onUpdateFieldName = () => {
    setState((prev) => ({
      ...prev,
      showUpdateFieldNameModal: false,
    }));
    axios
      .patch(
        `https://agrodigital.io/api/fields/${state.fieldId}/`,
        { name: updateNamePolygon },
        headers
      )
      .then(function () {
        //TOAST
        toast.success(t("alerts.updated"));
        setUpdateNamePolygon("");
        setState((prev) => ({
          ...prev,
          fieldInfo: {
            ...state.fieldInfo,
            name: updateNamePolygon,
          },
        }));
      })
      .catch(function (error) {
        // toast.error(
        //   "Error. Está intentando editar el nombre de una chacra compartida con usted."
        // );
        console.error("Error removing document: ", error);
      });
  };

  //Cuando importas un archivo kml
  const onDrawKmlData = () => {
    let kmlPolygonData = state.kmlPolygonData;
    let currentPolygonArr = state.polygonsArr;

    kmlPolygonData.map((item, index) => {
      let polygons = item.polygons;

      polygons.map((subItem, subIndex) => {
        let tempCoords = subItem.coords;
        let coords = [];
        let name = subItem.name;

        tempCoords.map((coord) => {
          let tempArr = [];
          tempArr[0] = coord.lng;
          tempArr[1] = coord.lat;

          coords.push(tempArr);
        });

        let tempPolygonInfo = {
          name: "",
          coordsArr: [],
        };
        tempPolygonInfo.name = name;
        tempPolygonInfo.coordsArr[0] = coords;
        currentPolygonArr.push(tempPolygonInfo);
      });
    });

    setNamePolygon("");
    setState((prev) => ({
      ...prev,
      polygonsArr: currentPolygonArr,
      otherPolygonsArr: currentPolygonArr,
      showAddConfirmModal: true,
      fromKml: true,
      // coordsArr: [],
      showKmlModal: false,
      kmlPolygonData: [],
      mapShow: false,
    }));
  };

  const onAddKmlPolygon = (polygonInfo) => {
    let kmlPolygonData = state.kmlPolygonData;

    let bOk = true;
    for (let i = 0; i < kmlPolygonData.length; i++) {
      if (kmlPolygonData[i].fileName === polygonInfo.fileName) {
        bOk = false;
        break;
      }
    }

    if (bOk === true) {
      kmlPolygonData.push(polygonInfo);
      setState((prev) => ({
        ...prev,
        kmlPolygonData: kmlPolygonData,
      }));
    }
  };

  const onRemoveKmlPolygon = (fileName) => {
    let kmlPolygonData = state.kmlPolygonData;

    const index = kmlPolygonData.findIndex((e) => e.fileName === fileName);

    kmlPolygonData.splice(index, 1);

    setState((prev) => ({
      ...prev,
      kmlPolygonData: kmlPolygonData,
    }));
  };

  const onKmlToggle = () => {
    setState((prev) => ({
      ...prev,
      showKmlModal: !state.showKmlModal,
    }));
  };

  //voy a hacer el filtro para que me guarde los sowing de ese poligono en particular
  const listZafras = (pol) => {
    axios
      .get(
        `https://agrodigital.io/api/logs/?field=${state.fieldId}&type=Sowing`,
        headers
      )
      .then(async (res) => {
        const result = [];
        let logsData = res.data;
        for (let i = 0; i < logsData.length; i++) {
          const log = logsData[i];
          if (log.crop !== "-" && log.crop !== null) {
            await axios
              .get(`https://agrodigital.io/api/crops/${log.crop}`, headers)
              .then((res) => {
                let cropData = res.data;
                log.crop = cropData.specie;
              });
          }
          if (log.polygons) {
            log.polygons.map((el) => {
              const polygon = el;
              if (polygon === pol.id) {
                result.push(log);
              }
            });
          }
        }
        let sortResult = result.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setState((prev) => ({
          ...prev,
          zafras: sortResult,
        }));
      });
  };
  const { centerLocation, zoom } = state;
  const polygonDeleteModal = (
    <Modal
      isOpen={state.showPolygonDeleteModal}
      toggle={() => onDeletePolygonToggle()}
    >
      <div className="modal-header justify-content-center">
        <h5 className="title title-up">{t("globals.confirmDeletePolygon")}</h5>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className={
              state.deletePolygonStatus === true
                ? "btn-link disabled"
                : "btn-link"
            }
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onDeletePolygonToggle()}
          >
            {t("buttons.noButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            color="primary"
            className={
              state.deletePolygonStatus === true
                ? "btn-link container disabled"
                : "btn-link"
            }
            type="button"
            onClick={() => {
              onDeletePolygon();
            }}
          >
          {t("buttons.yesButton")}

          </Button>
        </div>
      </div>
    </Modal>
  );

  const updateFieldNameModal = (
    <Modal
      isOpen={state.showUpdateFieldNameModal}
      toggle={() => {
        setState((prev) => ({
          ...prev,
          showUpdateFieldNameModal: false,
        }));
      }}
    >
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("globals.renameField")}</h4>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm={12}>
            <Input
              placeholder={t("globals.fieldName")}
              type="text"
              value={updateNamePolygon}
              onChange={(e) => setUpdateNamePolygon(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setUpdateNamePolygon("");
              setState((prev) => ({
                ...prev,
                showUpdateFieldNameModal: false,
              }));
            }}
          >
            {t("buttons.cancelButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className={
              updateNamePolygon !== "" ? "btn-link" : "btn-link disabled"
            }
            color="primary"
            type="button"
            onClick={() => {
              onUpdateFieldName();
            }}
          >
            {t("buttons.saveButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
  // MODAL CON LA IMAGEN DEL MAPA 
  const polygonAddModal = (
    <Modal
      isOpen={state.showAddModal}
      toggle={() => onAddModalToggle()}
      keyboard={false}
      style={{ maxWidth: "1200px", transform: "translate(0, 0)" }}
    >
      <div
        className="modal-header justify-content-center"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <h4 className="title title-up">{t("globals.drawPolygon")}</h4>
      </div>
      <div className="modal-body">
        <LoadingOverlay active={!state.mapShow} spinner text={t("globals.loading")}>
          {state.mapShow ? (
            <MapPolygonEdit
              updateModal={state.showUpdateModal}
              fromKml={state.fromKml}
              center={state.centerLocation}
              zoom={12}
              onChange={onCreatedPolygon}
              bounds={getAddMapBounds()}
              fieldList={state.fieldList}
              addStatus={true}
              createdList={state.otherPolygonsArr}
            />
          ) : (
            <div style={{ width: "100%", height: "300px" }}></div>
          )}
        </LoadingOverlay>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onAddModalToggle()}
          >
            {t("buttons.closeButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className="btn-link"
            color="primary"
            type="button"
            onClick={() => onKmlToggle()}
            disabled={state.disabledKmlButton}
          >
            <span className="btn-label">
              <i className="nc-icon nc-simple-add" />
            </span>
            {t("buttons.importKmlButton")}
          </Button>
        </div>
        {!state.createdWithSowing && (
          <div className="right-side">
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => processCreatedInfo()}
              disabled={state.disabledSavedButton}
            >
              {t("buttons.saveButton")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
  const polygonAsignModal = (
    <Modal
      isOpen={state.showReutModal}
      toggle={() => onReutilizarModalToggle()}
      keyboard={false}
      style={{ maxWidth: "1200px", transform: "translate(0, 0)" }}
    >
      <div
        className="modal-header justify-content-center"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <h4 className="title title-up">{t("globals.asignPolygon")}</h4>
      </div>
      <div className="modal-body">
        <ReutMapDetail
          center={state.centerLocation}
          zoom={12}
          bounds={getAddMapBounds()}
          selectedPolygons={getSelectedPolygons}
        />
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onReutilizarModalToggle()}
          >
            {t("buttons.cancelButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className="btn-link"
            color="primary"
            type="button"
            //realizar nueva funcion POST
            onClick={() => onSaveSelectPolygons(state.selectedPolygonsArr)}
          >
            {t("buttons.saveButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
  const yieldModal = (
    <Modal isOpen={state.showAllPoligonsYieldWarning}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("globals.attention")}</h4>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm={12}>
            <label className="text-center">
            {t("globals.reAsignFieldInfo")}
            </label>
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{
          justifyContent: "center",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <Button
          className="btn-link"
          color="danger"
          data-dismiss="modal"
          type="button"
          style={{ width: "150px" }}
          onClick={() => onCloseYieldAlert()}
        >
          {t("buttons.saveButton")}
        </Button>
      </div>
    </Modal>
  );

  // MODAL QUE APARECE CUANDO SE TERMINA DE DIBUJAR Y/O IMPORTAR EL POLIGONO
  const polygonAddConfirmModal = (
    <Modal
      isOpen={state.showAddConfirmModal}
      toggle={() => false}
      keyboard={true}
    >
      <div
        className="modal-header justify-content-center"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <h4 className="title title-up">{t("globals.insertPolygonData")}</h4>
      </div>
      <div className="modal-body">
        <Row className="justify-content-center">
          {!state.fromKml && !state.showReutModal && (
            <Col sm={12} md={10} lg={8}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-air-baloon" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("globals.polygonName")}
                  type="text"
                  value={namePolygon}
                  onChange={(e) => setNamePolygon(e.target.value)}
                />
              </InputGroup>
            </Col>
          )}
          <Col sm={12} md={10} lg={8}>
            {state.cropArr.length > 0 && (
              <FormGroup>
                <label>{t("globals.crop")}</label>
                <Select
                  name="crop"
                  className="react-select"
                  placeholder={t("globals.selectCrop")}
                  classNamePrefix="react-select"
                  value={state.crop}
                  onChange={(value) => changeCrop(value, false)}
                  options={state.cropArr}
                />
              </FormGroup>
            )}
          </Col>
          <Col sm={12} md={10} lg={8}>
            {state.varietyArrAux.length > 0 && (
              <FormGroup>
                <label>{t("globals.variety")}</label>
                <Select
                  name="variety"
                  className="react-select"
                  placeholder={t("globals.selectVariety")}
                  classNamePrefix="react-select"
                  value={state.variety}
                  onChange={(value) => {
                    setState((prev) => ({
                      ...prev,
                      variety: value,
                    }));
                  }}
                  options={state.varietyArrAux}
                />
              </FormGroup>
            )}
          </Col>
          <Col sm="8">
            {state.predecessorArray.length > 0 && (
              <FormGroup>
                <label>{t("globals.predecessor")}</label>
                <Select
                  name="crop"
                  className="react-select"
                  placeholder={t("globals.selectVariety")}
                  classNamePrefix="react-select"
                  value={state.predecesor}
                  onChange={(value) => changeCrop(value, true)}
                  options={state.predecessorArray}
                />
                {state.predecesorState === false ? (
                  <label style={{ color: "#ff0000" }}>{t("globals.requiredField")}</label>
                ) : null}
              </FormGroup>
            )}
          </Col>
          <Col sm={12} md={10} lg={8}>
            <FormGroup>
              <label>{t("globals.dateSowing")}</label>
              <ReactDatetime
                dateFormat="DD/MM/YYYY"
                value={state.sowing_date}
                onChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    sowing_date: value,
                  }));
                }}
                inputProps={{
                  className: "form-control",
                  placeholder: t("globals.insertDateSowing"),
                }}
                timeFormat={false}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onCloseAddConfirmModal()}
          >
            {t("buttons.cancelButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          {state.showReutModal ? (
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => createRegisterPolygonAsign()}
            >
              {t("buttons.saveButton")}
            </Button>
          ) : (
            <Button
              className="btn-link"
              color="primary"
              type="button"
              onClick={() => processCreatedInfo()}
              disabled={!state.crop}
            >
              {t("buttons.saveButton")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );

  const polygonUpdateModal = (
    <Modal
      isOpen={state.showUpdateModal}
      toggle={() => onUpdateModalToggle()}
      keyboard={false}
      style={{ maxWidth: "1200px", transform: "translate(0, 0)" }}
    >
      <div
        className="modal-header justify-content-center"
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <h4 className="title title-up">{t("globals.modifyPolygon")}</h4>
      </div>
      <div className="modal-body">
        <MapPolygonEdit
          fromKml={state.fromKml}
          center={state.centerLocation}
          zoom={12}
          initGeoJson={state.initGeoJson}
          onChange={onUpdatedPolygon}
          bounds={getUpdateMapBounds()}
          fieldList={state.fieldList}
          addStatus={false}
          createdList={state.otherPolygonsArr}
        />
        <br />
        <Row className="justify-content-center">
          <Col sm={12} md={10} lg={8}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-air-baloon" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={`${t("globals.polygonName")}...`}
                type="text"
                value={namePolygon}
                onChange={(e) => setNamePolygon(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
        <br />
        <p style={{ textAlign: "center" }}>
        {t("globals.redirectLogCreateSowing")}
        </p>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onUpdateModalToggle()}
          >
            {t("buttons.cancelButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className="btn-link"
            color="primary"
            type="button"
            onClick={() => processUpdatedInfo()}
          >
            {t("buttons.saveButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );
  const polygonTables = (
    <LoadingOverlay
      style={{ height: "100%" }}
      active={state.loadingTable}
      spinner
    >
      <Table responsive hover={true} className="detail-table">
        <thead className="text-primary">
          <tr>
            <th className="text-center">#</th>
            <th>{t("globals.polygon")}</th>
            <th>{t("globals.crop")}</th>
            <th>{t("globals.variety")}</th>
            <th>{t("globals.predecessor")}</th>
            <th>{t("globals.area")}</th>
            <th>{t("globals.dateSowing")}</th>
            <th className="text-center">{t("globals.action")}</th>
          </tr>
        </thead>
        <tbody>
          {state.polygons && state.polygons.length > 0 ? (
            state.polygons?.map((polygon, index) => (
              <tr
                style={
                  //para quitar el select
                  // state.selectStatus &&
                  state.selectIndex === index
                    ? { backgroundColor: "#F0FFF0" }
                    : { backgroundColor: "white" }
                }
                key={index}
                onMouseOver={() => MouseHover(index)}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    polyIndex: index,
                    selectIndex: index,
                    selectStatus: !state.selectStatus,
                  }));
                  listZafras(polygon);
                }}
                onMouseOut={() => MouseOut(index)}
              >
                <td className="text-center">{index + 1}</td>
                <td>{polygon.name}</td>
                <td>{polygon.crop}</td>
                <td>{polygon.variety}</td>
                <td>{polygon.predecessor ? polygon.predecessor : "-"}</td>
                <td>{polygon.area}</td>
                <td>{polygon.date ? polygon.dateTable : "-"}</td>
                <td className="text-center">
                  <Button
                    className="btn-icon"
                    color="success"
                    size="sm"
                    title={"Edit"}
                    type="button"
                    onClick={() => onShowUpdateModal(polygon)}
                    disabled={state.userOwner ? false : true}
                  >
                    <i className="fa fa-edit" />
                  </Button>
                  <Button
                    className="btn-icon"
                    color="danger"
                    size="sm"
                    title={"Delete"}
                    type="button"
                    onClick={() => onDeletePolygonInfo(polygon)}
                    disabled={state.userOwner ? false : true}
                  >
                    <i className="fa fa-trash-o" />
                  </Button>{" "}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="6"
                style={{ textAlign: "center", fontSize: "1.8em" }}
              >
                {t("noPolygonsInField")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </LoadingOverlay>
  );

  const polygonKmlModal = (
    <Modal isOpen={state.showKmlModal} backdrop={false} keyboard={false}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("globals.importPolygonKml")}</h4>
      </div>
      <div className="modal-body">
        <Dropzone
          onAddKmlPolygon={onAddKmlPolygon}
          onRemoveKmlPolygon={onRemoveKmlPolygon}
        />
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={() => onKmlToggle()}
          >
            {t("buttons.cancelButton")}
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className={
              state.kmlPolygonData.length > 0 ? "btn-link" : "btn-link disabled"
            }
            color="primary"
            type="button"
            onClick={() => {
              onDrawKmlData();
            }}
          >
            {t("buttons.importButton")}
          </Button>
        </div>
      </div>
    </Modal>
  );

  const closeModalSuperposition =()=>{
    setState((prev) => ({
      ...prev,
      mapShow:false,
      crop: null,
      variety: null,
      predecesor: null,
      sowing_date: null,
      addPolStatus: true,
      showAddConfirmModal: false,
      createdCoords: [],
      createdWithSowing:true,
      errorSuperposition: false,
      otherPolygonsArr: [],
      fromKml:false
    }));
  }
  return (
    <>
      {polygonAsignModal}
      {polygonAddModal}
      {polygonAddConfirmModal}
      {polygonUpdateModal}
      {updateFieldNameModal}
      {polygonDeleteModal}
      {yieldModal}
      {polygonKmlModal}
      {state.showDeleteModal && (
      <DeleteFieldModal 
      showDeleteModal={state.showDeleteModal} 
      onDeleteToggle={onDeleteToggle}
      fieldName={state.fieldInfo.name}
      confirmDeleteField={confirmDeleteField}
      setConfirmDeleteField={setConfirmDeleteField}
      onDeleteField={onDeleteField}
      />)}
      {state.errorSuperposition && (
        <ErrorCreateFieldModal
          closeModal={closeModalSuperposition}
          msg={t("globals.errorSuperposition")}
        />
      )}
      <div className="content">
        {!state.isLoading ? (
          <div>
            {state.fieldInfo.name && (
              <Row>
                <Col lg="10" md="9" sm="6" xs="12">
                  <Card className="card-stats">
                    <CardBody style={{ padding: "15px 15px 10px 15px" }}>
                      <label
                        onClick={() => {
                          setUpdateNamePolygon(state.fieldInfo.name);
                          setState((prev) => ({
                            ...prev,
                            showUpdateFieldNameModal: true,
                          }));
                        }}
                        className="polygon-name-label"
                        title={t("globals.clickUpdateName")}
                      >
                        {state.fieldInfo.name}
                      </label>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <NavLink
                    to={state.fieldId && `/admin/indexmap/${state.fieldId}`}
                  >
                    <Button
                      className="btn-btn btn-success btn-sm btn-block"
                      color="success"
                      title={t("globals.images")}
                      type="button"
                    >
                      <i className="fi flaticon-land" />
                      {t("globals.images")}
                    </Button>
                  </NavLink>
                  <NavLink to={`/admin/logs/${state.fieldId}`}>
                    <Button
                      style={{ marginLeft: "0px" }}
                      className="btn-success btn-sm btn-block"
                      color="success"
                      title={t("globals.logs")}
                      type="button"
                    >
                      <i class="nc-icon nc-book-bookmark" />
                      {t("globals.logs")}
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={3} md={6} sm={12}>
                {state.bounds.length > 0 && (
                  <Card className="card-stats">
                    <CardBody style={{ height: "300px", padding: "8px" }}>
                      <DetailMap
                        bounds={state.bounds}
                        center={centerLocation}
                        zoom={zoom}
                        hoverNumber={state.hoverNumber}
                        polygons={state.polygons}
                      />
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col lg={9} md={6} sm={12}>
                {state.polygons && (
                  <Card className="card-stats">
                    <CardBody style={{ height: "300px" }}>
                      {polygonTables}
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={6} sm={12}>
                {state.polygons && state.polygons.length > 0 && (
                  <Card className="h-100 card-stats">
                    <CardBody style={{ height: "300px" }}>
                      <LoadingOverlay active={state.loadingTable} spinner>
                        <h6>{t("globals.zafra")}</h6>
                        <Table responsive hover={true}>
                          <thead className="text-primary">
                            <tr>
                              <th>{t("globals.year")}</th>
                              <th>{t("globals.crop")}</th>
                            </tr>
                          </thead>
                          {/* aca el map preguntar si hay estado */}
                          {state.zafras && state.zafras.length > 0 ? (
                            state.zafras.map((zafra, index) => (
                              <tbody key={index}>
                                <tr
                                  key={new Date(zafra.date).getFullYear()}
                                  onClick={() => {
                                    yearChange("ACTUAL", zafra.date);
                                  }}
                                >
                                  <td>
                                    {zafra.date === "-" ||
                                    zafra.date === undefined
                                      ? "-"
                                      : new Date(zafra.date).getFullYear()}
                                  </td>
                                  <td>{zafra.crop}</td>
                                </tr>
                              </tbody>
                            ))
                          ) : (
                            <tbody>
                              <tr
                                key={new Date(
                                  state.polygons[state.polyIndex]?.date
                                ).getFullYear()}
                                onClick={() => {
                                  yearChange(
                                    "ACTUAL",
                                    state.polygons[state.polyIndex]?.date
                                  );
                                }}
                              >
                                <td>
                                  {state.polygons[state.polyIndex]?.date ===
                                    "-" ||
                                  !state.polygons[state.polyIndex]?.date
                                    ? "-"
                                    : new Date(
                                        state.polygons[state.polyIndex]?.date
                                      ).getFullYear()}
                                </td>
                                <td>{state.polygons[state.polyIndex]?.crop}</td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </LoadingOverlay>
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col lg={9} md={6} sm={12}>
                {state.endDate != null && state.endDate && (
                  <IndexChart
                    planBasic={providerPlanSource.planBasic}
                    planPlanet={providerPlanSource.planPlanet}
                    changePlan={providerPlanSource.changePlan}
                    changeDisabledButton={
                      providerPlanSource.changeDisabledButton
                    }
                    setStateMapDetail={setState}
                    fieldsUrl={state.fieldsUrl}
                    fieldId={state.fieldId}
                    polygons={state.polygons}
                    zeroDate={state.zeroDate}
                    endDate={state.endDate}
                  />
                )}
              </Col>
            </Row>

            {state.polygons.length > 0 && state.bounds.length &&(
              <TermicWidget polygons={state.polygons} fieldId={state.fieldId} bounds={state.bounds}/>
            )}

            <Row className="align-items-center justify-content-center">
              <Col sm={3}>
                <Button
                  onClick={() => onShowReutilizarPol()}
                  className="btn-round btn btn btn-info"
                  style={{ float: "center" }}
                  color="info"
                  disabled={state.userOwner ? false : true}
                >
                  <span className="btn-label">
                    <i className="fa fa-plus" />
                  </span>
                  {t("buttons.asignPolygonButton")}
                </Button>
              </Col>
              <Col sm={3}>
                <Button
                  onClick={() => onShowAddModal()}
                  className="btn-round btn btn btn-info"
                  color="info"
                  disabled={state.userOwner ? false : true}
                >
                  <span className="btn-label">
                    <i className="fa fa-plus" />
                  </span>
                  {t("buttons.createPolygonButton")}
                </Button>
              </Col>
              <Col sm={3}>
                <Button
                  onClick={() => onDeleteToggle()}
                  className={
                    state.deleteStatus === true
                      ? "btn-round  btn disabled"
                      : "btn-round btn"
                  }
                  color="danger"
                  disabled={state.userOwner ? false : true}
                >
                  {state.deleteStatus === true
                    ? `${t("globals.deleting")}...`
                    : t("buttons.deleteFieldButton")}
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <Spinner
              style={{ marginTop: "100px" }}
              animation="border"
              variant={"primary"}
            ></Spinner>
          </div>
        )}
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};
export default mapDetail;
