import React, { Component } from "react";
import { Row, FormGroup, Modal, Button, Col, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { AlertMultiPolygons, requestResult } from "./AlertLogsManyPolygons";

class CreateAndEditSeedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenUser: {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      },
      crop: null,
      variety: null,
      sowing_date: props.selectedLog ? props.selectedLog.date : null,
      observation: props.selectedLog ? props.selectedLog.obs : null,
      selectedLog: props.selectedLog ? props.selectedLog : null,
      predecesor: null,
      cropArr: [],
      varietyArr: [],

      cropState: true,
      predecesorState: true,
      sowingDateState: true,

      predecessorArray: [],
      loading: false,
      predecesorVariety: null,
      predecesorVarietyArr: [],
      recentDate: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
    };
  }

  componentDidMount() {
    this.fetchCrops();
    if (this.state.selectedLog === null) {
      this.checkPrevLogs();
    } else {
      this.checklogs();
    }
  }

  checklogs = async () => {
    let info = {
      fieldId: this.props.fieldId,
      polygonSelected: Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      ),
      date: this.state.sowing_date,
      tokenUser: this.state.tokenUser,
      type: "Sowing",
    };
    let result = await requestResult(info);
    this.setState((prev) => ({
      ...prev,
      alertMoreThanOnePolygon: result?.length > 1 && true,
      nameOfAllPolygons: result,
    }));
  };

  checkPrevLogs = async () => {
    let polygonsSelect = Object.keys(this.props.selectedPolygons).map((el) =>
      Number(el)
    );
    let polygonSowingData = await axios.get(
      `https://agrodigital.io/api/logs/?field=${this.props.fieldId}&type=Sowing`,
      this.state.tokenUser
    );
    let filterSowingForPolygon = polygonSowingData?.data.filter((sowing) =>
      sowing.polygons.some((polygons) => polygonsSelect.includes(polygons))
    );
    if (filterSowingForPolygon.length > 0) {
      let orderDate = filterSowingForPolygon.sort(
        (a, b) =>
          new Date(b.date + "T00:00:00") - new Date(a.date + "T00:00:00")
      );
      let dateSowing = orderDate[0];
      let fecha1 = new Date(dateSowing.date + "T00:00:00");
      let fecha2 = new Date();

      let resta = fecha2.getTime() - fecha1.getTime();
      let result = Math.round(resta / (1000 * 60 * 60 * 24));
      if (result <= 140) {
        this.setState({ recentDate: true });
      }
    }
  };
  fetchCrops = async () => {
    let vm = this;
    const cropsData = await axios.get(
      "https://agrodigital.io/api/crops/",
      this.state.tokenUser
    );
    let filterByCountry = cropsData.data.filter((el)=> el.region.includes(vm.props.userInfo.country))
    let dataInfo = [];
    let orderCrops = filterByCountry.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    orderCrops.map((item, index) => {
      if (item) {
        let tempData = item;
        let tempArr = {};
        tempArr["value"] = item.id;
        tempArr["label"] = tempData["name"];
        dataInfo.push(tempArr);
      }
    });
    this.setState(
      {
        predecessorArray: dataInfo,
        cropArr: dataInfo,
      },
      () => {
        if (vm.state.selectedLog && vm.state.selectedLog.cropName) {
          let crop = dataInfo.filter(
            (crop) => crop.label === vm.state.selectedLog.cropName
          )[0];
          if (crop) {
            let variety = vm.state.selectedLog.cropVar;
            this.changeCrop(crop, false, variety);
          }
        }
        if (vm.state.selectedLog && vm.state.selectedLog.predecessor) {
          let predecessor = dataInfo.filter(
            (crop) => crop.label === vm.state.selectedLog.predecessor
          )[0];

          this.changeCrop(predecessor, true, null);
        }
      }
    );
  };
  fetchVarieties = async (value, predecesor, preSelectedVariety) => {
    const varietyData = await axios.get(
      `https://agrodigital.io/api/varieties/`,
      this.state.tokenUser
    );
    let filterByCountry = varietyData.data.filter((el)=> el.region.includes(this.props.userInfo.country))
    let orderVarieties = filterByCountry.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    let dataInfo = [];
    orderVarieties.map((item, index) => {
      if (value.value === item.crop) {
        let tempArr = {};
        tempArr["value"] = item.id;
        tempArr["label"] = item.name;
        dataInfo.push(tempArr);
      }
    });

    if (predecesor) {
      this.setState({
        predecesorVarietyArr: dataInfo,
      });
    } else {
      this.setState({
        varietyArr: dataInfo,
      });
    }
  };

  changeCrop = (value, predecesor, preSelectedVariety) => {
    if (predecesor) {
      if (value.label === "Arroz" || value.label === "Rice") {
        this.fetchVarieties(value, predecesor);
        this.setState({
          predecesor: value,
          predecesorState: true,
        });
      } else {
        this.setState({
          predecesor: value,
          predecesorState: true,
          predecesorVarietyArr: [],
          predecesorVariety: null,
        });
      }
    } else {
      this.fetchVarieties(value, predecesor, preSelectedVariety);
      this.setState({
        crop: value,
        cropState: true,
      });
    }
  };

  saveButtonTapped() {
    if (this.isValidated()) {
      let insertData = {};
      let dateString = this.state.sowing_date.toString();
      if (dateString.length === 10) {
        insertData.date = moment(new Date(dateString + "T00:00:00")).format(
          "YYYY-MM-DD"
        );
      }
      if (dateString.length > 10) {
        insertData.date = moment(new Date(this.state.sowing_date)).format(
          "YYYY-MM-DD"
        );
      }
      insertData.obs = this.state.observation;
      insertData.crop = this.state.crop.value;
      insertData.variety =
        this.state.variety === null ? null : this.state.variety.value;
      insertData.deleted = false;
      insertData.predecessor = this.state.predecesor.value;
      insertData.type = "Sowing";

      let vm = this;
      this.setState({
        loading: true,
      });
      let polygonsIds = Object.keys(this.props.selectedPolygons).map((el) =>
        Number(el)
      );
      if (polygonsIds.length > 0 && this.props.fieldId) {
        insertData.field = this.props.fieldId
        if (this.state.selectedLog !== null) {
          axios
            .get(
              `https://agrodigital.io/api/logs/?field=${this.props.fieldId}`,
              this.state.tokenUser
            )
            .then((res) => {
              let data = {};
              for (let i = 0; i < res.data.length; i++) {
                const logReference = res.data[i];
                if (logReference.id === this.state.selectedLog.id) {
                  data = logReference;
                }
              }
              axios
                .patch(
                  `https://agrodigital.io/api/logs/${data.id}/`,
                  insertData,
                  this.state.tokenUser
                )
                .then((res) => {
                  vm.setState({
                    loading: false,
                  });
                  vm.closeButtonTapped(true);
                });
            });
        } else {
          insertData.polygons = polygonsIds;
          axios
            .post(
              "https://agrodigital.io/api/logs/",
              insertData,
              this.state.tokenUser
            )
            .then((res) => {
              vm.setState({
                loading: false,
              });
              vm.closeButtonTapped(true);
              if (this.props.dashboard) {
                this.props.onClose();
                this.props.onSaveTapped(true);
              }
            });
        }
      }
    }
  }

  clearStatus() {
    this.setState({
      crop: null,
      variety: null,
      predecesor: null,
      sowing_date: null,
      varietyArr: [],
      predecesorVariety: null,
      predecesorVarietyArr: [],
      recentDate: false,
      alertMoreThanOnePolygon: false,
      nameOfAllPolygons: [],
      observation: null,
    });
  }

  isValidated = () => {
    var isValidated = true;

    if (this.state.crop === null) {
      this.setState({
        cropState: false,
      });
      isValidated = false;
    }

    if (this.state.sowing_date == null) {
      this.setState({
        sowingDateState: false,
      });

      if (isValidated) {
        isValidated = false;
      }
    }

    if (this.state.predecesor === null) {
      this.setState({
        predecesorState: false,
      });
      if (isValidated) {
        isValidated = false;
      }
    }
    return isValidated;
  };

  closeButtonTapped(success = false) {
    this.clearStatus();
    this.props.onClose(success);
  }
  hideAlert() {
    this.setState({
      recentDate: false,
    });
  }
  render() {
    const { t } = this.props;
    const alertRecentDate = (
      <SweetAlert
        title=""
        showCancel
        confirmBtnText={t("buttons.yesButton")}
        cancelBtnText={t("buttons.noButton")}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="error"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.clearStatus()}
        btnSize="xs"
      >
        <div>
          <h6 style={{ fontSize: "20px" }}>{t("alerts.previusSowing")}</h6>
          <p style={{ margin: "0", fontWeight: "bold" }}>
            {t("globals.continueQuestion")}
          </p>
        </div>
      </SweetAlert>
    );
    return (
      <>
        {this.state.recentDate &&
          !this.state.selectedLog?.id &&
          alertRecentDate}

        <Modal isOpen={this.props.isOpen}>
          <div
            className="modal-header justify-content-center"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h4 className="title title-up">{t("globals.logSowingText")}</h4>
          </div>
          <div className="modal-body">
            {this.state.alertMoreThanOnePolygon &&
              this.state.selectedLog?.id &&
              this.state.nameOfAllPolygons.length > 1 && (
                <AlertMultiPolygons
                  nameOfAllPolygons={this.state.nameOfAllPolygons}
                />
              )}
            <br />
            <Row className="justify-content-center">
              <Col sm={12} md={10} lg={8}>
                {this.state.cropArr.length > 0 && (
                  <FormGroup>
                    <label>{t("globals.crop")}</label>
                    <Select
                      name="crop"
                      className="react-select"
                      placeholder={t("globals.selectCrop")}
                      classNamePrefix="react-select"
                      value={this.state.crop}
                      onChange={(value) => this.changeCrop(value, false, null)}
                      options={this.state.cropArr}
                    />
                    {this.state.cropState === false ? (
                      <label style={{ color: "#ff0000" }}>
                        {t("globals.requiredField")}
                      </label>
                    ) : null}
                  </FormGroup>
                )}
              </Col>
              <Col sm={12} md={10} lg={8}>
                {this.state.varietyArr.length > 0 && (
                  <FormGroup>
                    <label>{t("globals.variety")}</label>
                    <Select
                      name="variety"
                      className="react-select"
                      placeholder={t("globals.selectVariety")}
                      classNamePrefix="react-select"
                      value={this.state.variety}
                      onChange={(value) =>
                        this.setState({
                          variety: value,
                        })
                      }
                      options={this.state.varietyArr}
                    />
                  </FormGroup>
                )}
              </Col>
              <Col sm="8">
                {this.state.predecessorArray.length > 0 && (
                  <FormGroup>
                    <label>{t("globals.predecessor")}</label>
                    <Select
                      name="crop"
                      className="react-select"
                      placeholder={t("globals.selectCrop")}
                      classNamePrefix="react-select"
                      value={this.state.predecesor}
                      onChange={(value) => this.changeCrop(value, true, null)}
                      options={this.state.predecessorArray}
                    />
                    {this.state.predecesorState === false ? (
                      <label style={{ color: "#ff0000" }}>
                        {t("globals.requiredField")}
                      </label>
                    ) : null}
                  </FormGroup>
                )}
              </Col>
              <Col sm={12} md={10} lg={8}>
                <FormGroup>
                  <label>{t("globals.dateSowing")}</label>
                  <ReactDatetime
                    dateFormat="DD/MM/YYYY"
                    value={this.state.sowing_date}
                    onChange={(value) => {
                      this.setState({
                        sowingDateState: true,
                        sowing_date: value,
                      });
                    }}
                    inputProps={{
                      className: "form-control",
                      placeholder: t("globals.insertDateSowing"),
                    }}
                    timeFormat={false}
                  />
                  {this.state.sowingDateState === false ? (
                    <label style={{ color: "#ff0000" }}>
                      {t("globals.requiredField")}
                    </label>
                  ) : null}
                </FormGroup>
                <div>
                  <FormGroup>
                    <label>{t("globals.observation")}</label>
                    <Input
                      type="text"
                      value={this.state.observation || null}
                      onChange={(e) =>
                        this.setState({
                          observation: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>
          {!this.state.loading ? (
            <div className="modal-footer">
              <div className="left-side">
                <Button
                  className="btn-link"
                  color="danger"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.closeButtonTapped(false)}
                >
                  {t("buttons.cancelButton")}
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side">
                <Button
                  className="btn-link"
                  color="primary"
                  type="button"
                  onClick={() => this.saveButtonTapped()}
                >
                  {t("buttons.saveButton")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <CircularProgress color="secondary" />
            </div>
          )}
        </Modal>
      </>
    );
  }
}
export default CreateAndEditSeedForm;
