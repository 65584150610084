import { CircularProgress, FormGroup } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap";

const ShareModalDashboard = (props) => {
  const [t, i18n]= useTranslation("global")
  const [usersShared, setUsersSHared] = useState([])
  const [deleteUserField, setDeleteUserField] = useState([])


  useEffect(()=>{
    getUserShareByField()
  },[])

  const getUserShareByField= async ()=>{
    let fieldShare= props?.shareFieldId
    // Si la chacra seleccionada tiene usuarios compartidos
    // No mostrar nada si no tiene usuarios compartidos 
    if(fieldShare?.access.length > 0){
      let listUsers = await axios.get("https://agrodigital.io/api/auth/users/", props.tokenUser)
      if(listUsers.data.length > 0){
        // chequear que aparezcan solo los usuarios compartidos EN ESA chacra
        let usersByField=  listUsers.data.filter((el)=> fieldShare.access.some((el2)=> el.id === el2))
        setUsersSHared(usersByField)
      }
    }
  }

  const deleteUserFromMyField=(e)=>{
    let userSelected = e.target.value
    // Corroborar que los id no se repitan
    let checkRepeat = deleteUserField.filter((el)=> el === userSelected)
    // Si el id se repite significa que el usuario lo deseleccionó 
    // Los id en esta peticion son "string", cuando hagamos el boton de remover hay que pasarlos a Number
    if(checkRepeat.length > 0){
      let filterUser = deleteUserField.filter((el)=> el != userSelected)
      setDeleteUserField(filterUser)
    } else {
      setDeleteUserField(()=>[...deleteUserField, userSelected])
    }

  }

  const deleteUserInShareField = async ()=>{
    let fieldShare= props?.shareFieldId
    // usuarios seleccionados para ser removidos de la chacra compartida
    let usersId = deleteUserField.map((el)=> Number(el))
    // si el length es el mismo significa que el usuario seleccionó todos los usuarios
    if(usersId.length === fieldShare?.access.length){
      usersId = []
    } else{
      // poner en el array los usuarios que no han sido removidos
      let filterByUsersAccess = fieldShare?.access.filter((el)=> !usersId.includes(el))
      usersId = filterByUsersAccess
    }
    await axios
    .patch(
      `https://agrodigital.io/api/fields/${fieldShare.id}/`,
      { access: usersId },
      props.tokenUser
    )
    .then(()=> {
      // Agregar alerta acá
      toast.success(t("alerts.withoutAccessField"));
      props.closeShareModal(false)
    })
  }

  const shareField = async () => {
    let userEmail = props.shareEmail.trim();
    if (userEmail !== "" && props.shareFieldId != null) {
      let fieldId = props.shareFieldId;
      let fieldsInfo = await axios.get(
        "https://agrodigital.io/api/fields/",
        props.tokenUser
      );
      let searchEmail = await axios.get(
        `https://agrodigital.io/api/auth/users/?email=${userEmail}`,
        props.tokenUser
      );
      let userSharedId = searchEmail?.data[0]?.id;
      let filterField = fieldsInfo.data.filter((el) => el.id === fieldId?.id)[0];
      let noAccessRepeat = filterField.access.every(
        (el) => el !== userSharedId
      );

      if (noAccessRepeat) {
        let listAcess = [...filterField.access, userSharedId];
        await axios
          .patch(
            `https://agrodigital.io/api/fields/${fieldId.id}/`,
            { access: listAcess },
            props.tokenUser
          )
          .then(function () {
            props.closeShareModal(false);
            toast.success(t("alerts.shareField"));
          })
          .catch((error) => {
            toast.error(
              t("alerts.userNotFound"),
              {
                style: {
                  textAlign: "center",
                },
              }
            );
          });
      } else {
        toast.error(
          t("alerts.alreadyShareField"),
          {
            style: {
              textAlign: "center",
            },
          }
        );
        props.closeShareModal();
      }
    }
  };
  return (
    <Modal isOpen={props.showShareModal}>
      <div className="modal-header justify-content-center">
        <h4 className="title title-up">{t("alerts.shareField")}</h4>
      </div>
      {usersShared.length > 0 && (
      <div className="modal-body" style={{ borderBottom: "solid 1px #DDDDDD", padding:"10px 70px" }}>
        <h5 style={{ textAlign: "center", margin: "2px" }}>
          {t("globals.userShared")}
        </h5>
        <Row style={{marginTop:"15px"}}>
          <Col sm={12}>
            {/* <label>{t("globals.shareFieldDetails")}</label> */}
            {usersShared.map((users)=>(
            <FormGroup onChange={(e)=>{deleteUserFromMyField(e)}}>
              <Input type="checkbox" value={users.id}/> <Label check>{users.first_name}</Label>
            </FormGroup>
            ))}
          </Col>
        </Row>
        {deleteUserField.length > 0 && (
        <div className="modal-footer" style={{border:"none", textAlign:"center", display:"block"}}>
          <div className="left-side">
            <Button
              style={{padding:"8px"}}
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => deleteUserInShareField()}
            >
             {t("buttons.removeUserButton")}
            </Button>
          </div>
        </div>
        )}
      </div>
      
      )}
      <div className="modal-body">
        <Row>
          <Col sm={12}>
            <label>{t("globals.shareFieldDetails")}</label>
            <Form className="form" onSubmit={(e) => e.preventDefault()}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="nc-icon nc-air-baloon" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  onChange={(e) => props.setShareEmail(e.target.value)}
                />
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </div>
      {props.loadingShare ? (
        <div className="text-center py-4">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="modal-footer">
          <div className="left-side">
            <Button
              className="btn-link"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => props.closeShareModal()}
            >
              {t("buttons.noButton")}
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              className={
                props.shareEmail !== "" ? "btn-link" : "btn-link disabled"
              }
              color="primary"
              type="button"
              onClick={() => {
                shareField();
              }}
            >
              {t("buttons.yesButton")}
            </Button>
          </div>
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </Modal>
  );
};

export default ShareModalDashboard;
